export default {
  async pending(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/assigned-to-me?status=pending`,
      headers: this.headers,
      ...config,
    });
  },
  async contacted(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/assigned?status=contacted`,
      headers: this.headers,
      ...config,
    });
  },
  async stats(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/assigned/stats`,
      headers: this.headers,
      ...config,
    });
  },
};
