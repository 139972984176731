import Vue from 'vue';
import apis from './apis';

const AsyncFunction = Object.getPrototypeOf(async function () {}).constructor;

Vue.mixin({
  data() {
    return {
      apiRequests: apis,
    };
  },
  methods: {
    async sendRequest(path = '', ...params) {
      const fullPath = path.split('.');
      let request;

      try {
        request = fullPath.reduce(
          (last, current) => last[current],
          this.apiRequests
        );
      } catch (e) {
        console.warn(e);
      }

      if (!request || request.constructor !== AsyncFunction) {
        throw new Error(
          `Invaliid Request ${path}; Request Not Registered In /src/mixins/apis.js`
        );
      }

      return await request.apply(this, [...params]);
    },
  },
});
