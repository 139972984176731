export default {
  async govtId(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/documents/govt_id/${userId}`,
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      ...config,
    });
  },
  async workId(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/documents/work_id/${userId}`,
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      ...config,
    });
  },
  async bankStatement(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/documents/bank_statement/${userId}`,
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      ...config,
    });
  },
  async custom(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/documents/custom`,
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      ...config,
    });
  },
  async getUrl(documentId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/staff/documents/${documentId}`,
      headers: this.headers,
      ...config,
    });
  },
};
