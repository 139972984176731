export default {
  async add(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/branch`,
      headers: this.headers,
      ...config,
    });
  },
  async destroy(branchId, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/admin/merchants/branch/${branchId}`,
      headers: this.headers,
      ...config,
    });
  },
  async update(branchId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/merchants/branch/${branchId}`,
      headers: this.headers,
      ...config,
    });
  },
};
