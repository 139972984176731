export default {
  async letterOfIndebtedness(loanId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/loans/letter-of-indebtedness/${loanId}`,
      headers: this.headers,
      ...config,
    });
  },
  personal: require('./personal').default,
};
