export default {
  async broadcast(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/communications`,
      headers: this.headers,
      ...config,
    });
  },
  templates: require('./templates').default,
};
