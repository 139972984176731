export default {
  async getPlans(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/accessible-plans`,
      headers: this.headers,
      ...config,
    });
  },
  async subscribe(config = {}) {
    this.$refs.payLaterModal.close();
    return await this.$post({
      url: `${this.$baseurl}/personal/card/request/final`,
      headers: this.headers,
      ...config,
    });
  },
  async verifyAccount(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/card/request`,
      headers: this.headers,
      ...config,
    });
  },
};
