import { accountTypes } from './utils/pages_utils';

export default {
  expenses: {
    route: {
      name: 'expenses',
      path: `/:accountType(${accountTypes})/expenses`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'expenses-index' */ '@/views/app/expenses/all/Index.vue'
        ),
      meta: {
        title: 'Expenses',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_expenses',
      'credpal:can_flag_expenses',
      'credpal:can_approve_expenses',
      'credpal:can_delete_expenses',
      'company:can_view_expenses',
      'company:can_flag_expenses',
      'company:can_approve_expenses',
      'company:can_delete_expenses',
      'personal:can_view_expenses',
    ],
  },
  'expense-requests': {
    route: {
      name: 'expense-requests',
      path: `/:accountType(${accountTypes})/expenses/requests`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'expense-requests' */ '@/views/app/expenses/requests/Index.vue'
        ),
      meta: {
        title: 'Expense Requests',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'company:can_view_expense_requests',
      'company:can_approve_expense_requests',
      'company:can_decline_expense_requests',
      'company:can_delete_expense_requests',
    ],
  },
  'expense-policies': {
    route: {
      name: 'expense-policies',
      path: `/:accountType(${accountTypes})/expenses/policies`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'expense-policies' */ '@/views/app/expense-policies/all/Index.vue'
        ),
      meta: {
        title: 'Expense Policies',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'company:can_view_expense_policies',
      'company:can_create_expense_policies',
      'company:can_update_expense_policies',
      'company:can_delete_expense_policies',
    ],
  },
  'expense-policies-new': {
    route: {
      name: 'expense-policies-new',
      path: `/:accountType(${accountTypes})/expenses/policies/new`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'expense-policies-new' */ '@/views/app/expense-policies/new/Index.vue'
        ),
      meta: {
        title: 'Expense Policies',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['company:can_create_expense_policies'],
  },
}