export default {
  admins: require('./admins').default,
  affiliate: require('./affiliate').default,
  assignments: require('./assignments').default,
  cards: require('./cards').default,
  communications: require('./communications').default,
  companies: require('./companies').default,
  configurations: require('./configurations').default,
  fail: require('./fail').default,
  lenders: require('./lenders').default,
  loans: require('./loans').default,
  markAsProcessed: require('./markAsProcessed').default,
  merchants: require('./merchants').default,
  permissions: require('./permissions').default,
  promos: require('./promos').default,
  referral: require('./referral').default,
  refund: require('./refund').default,
  repayments: require('./repayments').default,
  roles: require('./roles').default,
  secureCards: require('./secureCards').default,
  statistics: require('./statistics').default,
  users: require('./users').default,
  wallets: require('./wallets').default,
  invest: require('./invest').default,
  pushNotifications: require('./pushNotifications').default,
};
