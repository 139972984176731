export default {
  async addMembers(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/assignments/admins/addMembers`,
      headers: this.headers,
      ...config,
    });
  },
  async removeMembers(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/assignments/admins/removeMembers`,
      headers: this.headers,
      ...config,
    });
  },
  async admins(category, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/assignments/admins/${category}`,
      headers: this.headers,
      ...config,
    });
  },
  async assign(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/assignments/assign`,
      headers: this.headers,
      ...config,
    });
  },
  async unassign(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/assignments/unassign`,
      headers: this.headers,
      ...config,
    });
  },
  async contact(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/assignments/mark-as-contacted`,
      headers: this.headers,
      ...config,
    });
  },
  async processCount(type, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/count/${type}`,
      headers: this.headers,
      ...config,
    });
  },
  async requeue(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/requeue`,
      headers: this.headers,
      ...config,
    });
  },
};
