export default {
  loggedIn(user) {
    window.dengage('setContactKey', user.email);
    // console.log(user);
  },
  navigate(to) {
    const accountType = to.params.accountType

    if (accountType) {
      this.$store.dispatch('resources/fetchAll', accountType);
    }

  }
}
