import { accountTypes } from './utils/pages_utils';

export default {
    groups: {
        route: {
          name: 'groups',
          path: `/:accountType(${accountTypes})/groups`,
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'groups-index' */ '@/views/app/groups/all/Index.vue'
            ),
          meta: {
            title: 'Departments',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'company:can_view_departments',
          'company:can_update_departments',
          'company:can_delete_departments',
        ],
      },
      'groups-new': {
        route: {
          name: 'groups-new',
          path: `/:accountType(${accountTypes})/groups/new`,
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'groups-new' */ '@/views/app/groups/new/Index.vue'
            ),
          meta: {
            title: 'New Group',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['company:can_create_departments'],
      },
}