import { accountTypes } from './utils/pages_utils';

export default {
  admins: {
    route: {
      name: 'admins',
      path: `/:accountType(${accountTypes})/admins`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'admins-index' */ '@/views/app/admins/all/Index.vue'
        ),
      meta: {
        title: 'All Admins',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_admins',
      'credpal:can_update_admins',
      'credpal:can_delete_admins',
      'company:can_view_admins',
      'company:can_update_admins',
      'company:can_delete_admins',
    ],
  },
  'admins-new': {
    route: {
      name: 'admins-new',
      path: `/:accountType(${accountTypes})/admins/new`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'admins-index' */ '@/views/app/admins/new/Index.vue'
        ),
      meta: {
        title: 'New Admin',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_create_admins', 'company:can_create_admins'],
  },
}