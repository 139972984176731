import { path, admin } from './utils/pages_utils';

export default {
  'push-notifications': {
    route: {
      name: 'push-notifications',
      path: path('push-notifications', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'push-notifications' */ '@/views/app/push-notifications/Index.vue'
        ),
      meta: {
        title: 'Push Notifications',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
    ],
    permissions: ['credpal:can_manage_push_notifications'],
  },

  'push-notifications/category': {
    route: {
      name: 'push-notifications/category',
      path: path('push-notifications/category/:id', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'push-notifications/category/users' */ '@/views/app/push-notifications/categories/View.vue'
        ),
      meta: {
        title: 'Push Notifications Category Users',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
    ],
    permissions: ['credpal:can_manage_push_notifications'],
  },
};
