import { accountTypes } from './utils/pages_utils';

export default {
  settlements: {
    route: {
      name: 'settlements',
      path: `/:accountType(${accountTypes})/settlements`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'settlements-index' */ '@/views/app/settlements/all/Index.vue'
        ),
      meta: {
        title: 'Settlements',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_settlements',
      'credpal:can_delete_settlements',
    ],
  },
  'settlements-due': {
    route: {
      name: 'settlements-due',
      path: `/:accountType(${accountTypes})/settlements/due`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'settlements-due' */ '@/views/app/settlements/due/Index.vue'
        ),
      meta: {
        title: 'Settlements',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'can_view_settlements',
      'can_pay_settlements',
      'can_delete_settlements',
    ],
  },
}