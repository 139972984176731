export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/roles`,
      headers: this.headers,
      ...config,
    });
  },
  async update(config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/roles/users/update`,
      headers: this.headers,
      ...config,
    });
  },
};
