export default {
    async pending(config = {}) {
      return await this.$get({
        url: `${this.$caasUrl}/payout/transactions/false`,
        headers: this.headers,
        ...config,
      });
    },
    async paid(config = {}) {
      return await this.$get({
        url: `${this.$caasUrl}/payout/transactions/true`,
        headers: this.headers,
        ...config,
      });
    },
    async merchantList(config = {}) {
      return await this.$get({
        url: `${this.$caasUrl}/checkout/merchants`,
        headers: this.headers,
        ...config,
      });
    },
    async merchantTransactions(merchantId, config = {}) {
      return await this.$get({
        url: `${this.$caasUrl}/checkout/merchants/${merchantId}/transactions`,
        headers: this.headers,
        ...config,
      });
    },
    async merchantRefunds(merchantId, config = {}) {
      return await this.$get({
        url: `${this.$caasUrl}/refund/merchants/${merchantId}/refunds`,
        headers: this.headers,
        ...config,
      });
    },
    async leads(config = {}) {
      return await this.$get({
        url: `${this.$caasUrl}/users/prospect`,
        headers: this.headers,
        ...config,
      });
    },
    async payoutSettlement(config = {}) {
      return await this.$post({
        url: `${this.$caasUrl}/payout/settlement`,
        headers: this.headers,
        ...config,
    });
  },
  async allRefunds(merchantId, config = {}) {
    return await this.$get({
      url: `${this.$caasUrl}/payout/transactions/true`,
      headers: this.headers,
      ...config,
    });
  },
  async allTransaction(merchantId, config = {}) {
    return await this.$get({
      url: `${this.$caasUrl}/payout/transactions/true`,
      headers: this.headers,
      ...config,
    });
  },
  async getSpends(config = {}) {
    return await this.$get({
      url: `${this.$caasUrl}/checkout/merchants/transactions`,
      headers: this.headers,
      ...config,
    });
  },
  async merchantCheckouts(merchantId, config = {}) {
    return await this.$get({
      url: `${this.$caasUrl}/checkout/merchants/${merchantId}/transactions`,
      headers: this.headers,
      ...config,
    });
  },
  async Onboarded(config = {}) {
    return await this.$put({
      url: `${this.$caasUrl}/users/Onboarded`,
      headers: this.headers,
      ...config,
    });
  },
};
  