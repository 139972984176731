import { path, corporate } from './utils/pages_utils';

export default {
    onboarding: {
        route: {
          name: 'onboarding',
          path: path('onboarding', corporate),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'onboarding' */ '@/views/app/onboarding/Index.vue'
            ),
          meta: {
            title: 'Onboarding',
          },
        },
        middlewares: ['auth', 'password_changed'],
        permissions: ['company:can_view_onboarding'],
      },
  'register-business': {
    route: {
      name: 'register-business',
      path: '/register/business',
      parent: 'register',
      component: () =>
        import(
          /* webpackChunkName: 'register' */ '@/views/auth/register/business/Index.vue'
        ),
      meta: {
        title: 'Create Your Account',
      },
    },
    middlewares: ['guest'],
    permissions: ['*'],
  },
  register: {
    route: {
      name: 'register',
      path: '/register/:source?',
      parent: 'register',
      component: () =>
        import(
          /* webpackChunkName: 'register' */ '@/views/auth/register/personal/Index.vue'
        ),
      meta: {
        title: 'Create Your Account',
      },
    },
    middlewares: ['guest'],
    permissions: ['*'],
  },
  login: {
    route: {
      name: 'login',
      path: '/login',
      parent: 'auth',
      component: () =>
        import(/* webpackChunkName: 'login' */ '@/views/auth/Login.vue'),
      meta: {
        title: 'Login To Your Account',
      },
    },
    middlewares: ['guest'],
    permissions: ['*'],
  },
  logout: {
    route: {
      name: 'logout',
      path: '/logout',
      parent: 'auth',
      component: () => import('@/views/auth/Logout.vue'),
      meta: {
        title: 'Logging out...',
      },
    },
    middlewares: [],
    permissions: ['*'],
  },
  'update-password': {
    route: {
      name: 'update-password',
      path: '/password/update',
      parent: 'auth',
      component: () =>
        import(
          /* webpackChunkName: 'update-password' */ '@/views/auth/UpdatePassword.vue'
        ),
      meta: {
        title: 'Update Your Password',
      },
    },
    middlewares: ['auth'],
    permissions: ['*'],
  },
  'password-reset': {
    route: {
      name: 'password-reset',
      path: '/password/reset/:token',
      parent: 'auth',
      component: () =>
        import(
          /* webpackChunkName: 'password-reset' */ '@/views/auth/ResetPassword.vue'
        ),
      meta: {
        title: 'Reset your password',
        allowEndUsers: true,
      },
    },
    middlewares: [],
    permissions: ['*'],
  },
}