export default {
  async all(companyId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/statement/company/${companyId}`,
      headers: this.headers,
      ...config,
    });
  },
  async download(statementId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/statement/download/${statementId}`,
      headers: this.headers,
      ...config,
    });
  },
};
