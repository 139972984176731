import { path, admin } from './utils/pages_utils';

export default {
  'invest-rates': {
    route: {
      name: 'invest-rates',
      path: path('invest/invest-rates', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'invest-rates' */ '@/views/app/invest/rates/Index.vue'
        ),
      meta: {
        title: 'Invest Rates',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_invest'],
  },
  invest: {
    route: {
      name: 'invest',
      path: path('invests', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'invest' */ '@/views/app/invest/invest/Index.vue'
        ),
      meta: {
        title: 'Invest',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_invest'],
  },
  'invest-configuration': {
    route: {
      name: 'invest-configuration',
      path: path('invest-configuration', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'invest-configuration' */ '@/views/app/invest/Configuration.vue'
        ),
      meta: {
        title: 'Invest Configuration',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_invest_configurations'],
  },
  'tenures-configuration': {
    route: {
      name: 'tenures-configuration',
      path: path('tenures-configuration', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'tenures-configuration' */ '@/views/app/invest/TenuresConfiguration.vue'
        ),
      meta: {
        title: 'Tenure Configuration',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_update_system_configuration'],
  },
}