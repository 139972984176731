export default {
  async pending(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/transactions/settlements/pending`,
      headers: this.headers,
      ...config,
    });
  },
  async paid(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/transactions/settlements/paid`,
      headers: this.headers,
      ...config,
    });
  },
};
