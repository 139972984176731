export default {
  async transactions(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/baxi/transaction/${this.user.id}`,
      headers: this.headers,
      ...config,
    });
  },
  async categories(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/baxi/biller-services`,
      headers: this.headers,
      ...config,
    });
  },
  async dataPlans(network, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/baxi/provider-bundles/${network}`,
      headers: this.headers,
      ...config,
    });
  },
  async buyAirtime(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/baxi/airtime-request`,
      headers: this.headers,
      ...config,
    });
  },
  async buyData(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/baxi/databundle-request`,
      headers: this.headers,
      ...config,
    });
  },
  async verifyMeterNumber(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/baxi/verify-electric-user`,
      headers: this.headers,
      ...config,
    });
  },
  async buyElectricity(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/baxi/electricity-request`,
      headers: this.headers,
      ...config,
    });
  },
  async cableTvPlans(provider, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/baxi/multichoice-list/${provider}`,
      headers: this.headers,
      ...config,
    });
  },
  async verifyCableTvAccount(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/baxi/verify-account-details`,
      headers: this.headers,
      ...config,
    });
  },
  async buyCableTvBundle(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/baxi/multichoice-request`,
      headers: this.headers,
      ...config,
    });
  },
};
