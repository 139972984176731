export default {
  async bankStatement(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement`,
      headers: this.headers,
      ...config,
    });
  },
  async confirmMbs(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/mbs/confirm`,
      headers: this.headers,
      ...config,
    });
  },
  async govtId(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/documents/govt-id`,
      headers: this.headers,
      ...config,
    });
  },
  async mbs(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/mbs`,
      headers: this.headers,
      ...config,
    });
  },
  async mobile(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/mobile`,
      headers: this.headers,
      ...config,
    });
  },
  async mono(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/mono`,
      headers: this.headers,
      ...config,
    });
  },
  async okra(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/okra`,
      headers: this.headers,
      ...config,
    });
  },
  async ussd(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/ussd`,
      headers: this.headers,
      ...config,
    });
  },
  async workId(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/documents/work-id`,
      headers: this.headers,
      ...config,
    });
  },
};
