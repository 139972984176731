import { path, admin, customer, accountTypes } from './utils/pages_utils';

export default {
  'cards-transaction': {
    route: {
      name: 'cards-transaction',
      path: path('cards/transaction', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cards-transaction' */ '@/views/app/cards/transaction/Index.vue'
        ),
      meta: {
        title: 'Cards Transaction',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_card_requests',
      'credpal:can_approve_card_requests',
      'credpal:can_decline_card_requests',
    ],
  },
  'virtual-card-transactions': {
    route: {
      name: 'virtual-card-transactions',
      path: path('virtual-cards/transactions', admin),
      parent: 'app',
      component: () =>
        import('@/views/app/virtual-cards/transactions/Index.vue'),
      meta: {
        title: 'Virtual Card Transactions',
      },
    },

    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_virtual_card_transactions'],
  },
  'cash-wallet-limit-requests': {
    route: {
      name: 'cash-wallet-limit-requests',
      path: path('cash/limit-requests', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cash-wallet-limit-requests' */ '@/views/app/cash/limit-requests/Index.vue'
        ),
      meta: {
        title: 'Cash Transaction Limit Requests',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_update_user_wallet_limit'],
  },
  'cash-transactions': {
    route: {
      name: 'cash-transactions',
      path: path('cash/transactions', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cash-transactions' */ '@/views/app/cash/transactions/Index.vue'
        ),
      meta: {
        title: 'Cash Transactions',
      },
    },

    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_cash_transactions',
      'credpal:can_view_loanbot_transactions',
    ],
  },
  'underwriters-statistics': {
    route: {
      name: 'underwriters-statistics',
      path: path('underwriters/statistics', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'underwriters-statistics' */ '@/views/app/underwriters/statistics/Index.vue'
        ),
      meta: {
        title: 'Transactions',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  refunds: {
    route: {
      name: 'refunds',
      path: `/:accountType(${accountTypes})/refunds`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'refunds-index' */ '@/views/app/refunds/Index.vue'
        ),
      meta: {
        title: 'Refunds',
      },
    },
    middlewares: ['auth'],
    permissions: ['credpal:can_refund_transactions'],
  },
  'bills-transactions': {
    route: {
      name: 'bills-transactions',
      path: path('bills-transactions', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'bills-transactions' */ '@/views/app/bills-transactions/Index.vue'
        ),
      meta: {
        title: 'Airtime & Bills',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  transactions: {
    route: {
      name: 'transactions',
      path: `/:accountType(${accountTypes})/transactions`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'payments' */ '@/views/app/transactions/Index.vue'
        ),
      meta: {
        title: 'Transactions',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'expense-transactions': {
    route: {
      name: 'expense-transactions',
      path: path('expense-transactions', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'expense-transactions' */ '@/views/app/expense-transactions/transactions/Index.vue'
        ),
      meta: {
        title: 'Expene Transactions',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_expense_transactions'],
  },
  'expense-configuration': {
    route: {
      name: 'expense-configuration',
      path: path('expense-configuration', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'expense-configuration' */ '@/views/app/expense-transactions/Configuration.vue'
        ),
      meta: {
        title: 'Expense Configuration',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_expense_configuration'],
  },

  /** Merchant Transactions */

  'merchant-transaction': {
    route: {
      name: 'merchant-transaction',
      path: path('merchants/transaction', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'merchants-transaction' */ '@/views/app/merchant/orders/Index.vue'
        ),
      meta: {
        title: 'Merchant Transaction',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-incomplete-transaction': {
    route: {
      name: 'merchant-incomplete-transaction',
      path: path('merchants/incomplete-transaction', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'merchants-incomplete-transaction' */ '@/views/app/merchant/orders/IncompleteOrders.vue'
        ),
      meta: {
        title: 'Merchant Incomplete Transaction',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  /** Payments */
  payments: {
    route: {
      name: 'payments',
      path: `/:accountType(${accountTypes})/payments`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'payments' */ '@/views/app/payments/all/Index.vue'
        ),
      meta: {
        title: 'Payments',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'payment-history': {
    route: {
      name: 'payment-history',
      path: `/:accountType(${accountTypes})/payments/history`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'payment-history' */ '@/views/app/payments/history/Index.vue'
        ),
      meta: {
        title: 'Payment History',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },

  /** Bills */

  bills: {
    route: {
      name: 'bills',
      path: path('bills', customer),
      parent: 'app',
      component: () =>
        import(/* webpackChunkName: 'bills' */ '@/views/app/bills/Index.vue'),
      meta: {
        title: 'Airtime & Bills',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'company:can_view_bills',
      'company:can_pay_bills',
      'personal:can_view_bills',
      'personal:can_pay_bills',
    ],
  },
  transfers: {
    route: {
      name: 'transfers',
      path: `/:accountType(${accountTypes})/transfers`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'transfers-index' */ '@/views/app/transfers/all/Index.vue'
        ),
      meta: {
        title: 'Transfers',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'company:can_view_expenses',
      'company:can_flag_expenses',
      'company:can_approve_expenses',
      'company:can_delete_expenses',
    ],
  },
  'incoming-wema-transactions': {
    route: {
      name: 'incoming-wema-transactions',
      path: path('incoming-wema-transactions', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'incoming-wema-transactions' */
          '@/views/app/wema-transactions/incoming/Index.vue'
        ),
      meta: {
        title: 'Incoming Wema Transactions',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_wema_transactions'],
  },
  'outgoing-wema-transactions': {
    route: {
      name: 'outgoing-wema-transactions',
      path: path('outgoing-wema-transactions', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'outgoing-wema-transactions' */
          '@/views/app/wema-transactions/outgoing/Index.vue'
        ),
      meta: {
        title: 'Outgoing Wema Transactions',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_wema_transactions'],
  },
}
