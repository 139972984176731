import { path, admin, corporate, customer } from './utils/pages_utils';

export default {
  'tangerine-personal-loans': {
    route: {
      name: 'tangerine-personal-loans',
      path: path('disbursement/personal/loans/tangerine', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'tangerine-personal-loans' */ '@/views/app/disbursement/lenders/loans/tangerineLife/Index.vue'
        ),
      meta: {
        title: 'Tangerine Disbursement',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loan_disbursements',
      'credpal:can_disburse_loans',
      'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements',
    ],
  },
  'tangerine-credit-card-disbursements': {
    route: {
      name: 'tangerine-credit-card-disbursements',
      path: path('disbursement/personal/credit-card/tangerine', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'tangerine-credit-card-disbursements' */ '@/views/app/disbursement/lenders/credit-card/tangerineLife/Index.vue'
        ),
      meta: {
        title: 'Tangerine Repayments',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loan_disbursements',
      'credpal:can_disburse_loans',
      'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements',
    ],
  },   
  'disbursement-statistics': {
    route: {
      name: 'disbursement-statistics',
      path: path('disbursement/statistics', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'disbursement-statistics' */ '@/views/app/disbursement/statistics/Index.vue'
        ),
      meta: {
        title: 'Disbursement',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loan_disbursements',
      'credpal:can_disburse_loans',
      'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements',
    ],
  },
  'disbursement-personal-loans': {
    route: {
      name: 'disbursement-personal-loans',
      path: path('disbursement/personal/loans', admin, customer),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'disbursement-personal-loans' */ '@/views/app/disbursement/personal-loans/Index.vue'
        ),
      meta: {
        title: 'Disbursement',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loan_disbursements',
      'credpal:can_disburse_loans',
      'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements',
    ],
  },
  'disbursement-credit-card-loans': {
    route: {
      name: 'disbursement-credit-card-loans',
      path: path('disbursement/credit-card/loans', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'disbursement-credit-card-loans' */ '@/views/app/disbursement/credit-card-loans/Index.vue'
        ),
      meta: {
        title: 'Disbursement',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loan_disbursements',
      'credpal:can_disburse_loans',
      'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements',
    ],
  },
  'disbursement-business-loans': {
    route: {
      name: 'disbursement-business-loans',
      path: path('disbursement/business/loans', admin, corporate),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'disbursement-business-loans' */ '@/views/app/disbursement/business-loans/Index.vue'
        ),
      meta: {
        title: 'Disbursement',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loan_disbursements',
      'credpal:can_disburse_loans',
      'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements',
    ],
  },

}