export default {
  async activate(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/card/activate`,
      headers: this.headers,
      ...config,
    });
  },
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/staff/cards/${this.user.id}`,
      headers: this.headers,
      ...config,
    });
  },
  async requestPin(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/card/pin/request`,
      headers: this.headers,
      ...config,
    });
  },
};
