export default {
  async fundCreditWallet(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/wallet/fund-credit-wallet`,
      headers: this.headers,
      ...config,
    });
  },
  async fundDebitWallet(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/wallet/fund-debit-wallet`,
      headers: this.headers,
      ...config,
    });
  },
  async get(companyId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/wallet/${companyId}`,
      headers: this.headers,
      ...config,
    });
  },
};
