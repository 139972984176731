export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/loans`,
      headers: this.headers,
      ...config,
    });
  },
  async statistics(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/loans/statistics`,
      headers: this.headers,
      ...config,
    });
  },
  async view(loanId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/loans/${loanId}`,
      headers: this.headers,
      ...config,
    });
  },
  async approve(loanId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/${loanId}/approve`,
      headers: this.headers,
      ...config,
    });
  },
  async decline(loanId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/${loanId}/decline`,
      headers: this.headers,
      ...config,
    });
  },
  async modify(loanId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/personal/loans/${loanId}/modify`,
      headers: this.headers,
      ...config,
    });
  },
  async total(status, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/loans/total?status=${status}`,
      headers: this.headers,
      ...config,
    });
  },
  async disburse_many(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/disburse-many`,
      headers: this.headers,
      ...config,
    });
  },
  async disburse_credpal_pay(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/disburse-credpalpay-many`,
      headers: this.headers,
      ...config,
    });
  },
  async undisburse_many(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/undisburse-many`,
      headers: this.headers,
      ...config,
    });
  },
  async send_to_tangarine(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/tangerine/disburse`,
      headers: this.headers,
      ...config,
    });
  },
  async pay_tangerine(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/tangerine/pay-many`,
      headers: this.headers,
      ...config,
    });
  },
  async payTangerineBatch(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/tangerine/pay-batch`,
      headers: this.headers,
      ...config,
    });
  },
  async officersVerify(loanId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/${loanId}/officers-verify`,
      headers: this.headers,
      ...config,
    });
  },
  async operationsVerify(loanId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/${loanId}/operations-verify`,
      headers: this.headers,
      ...config,
    });
  },
  async settle_many_repayments(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/repayments/settle-many`,
      headers: this.headers,
      ...config,
    });
  },
  async loanRequestStop(loanId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loans/${loanId}/request-stop`,
      headers: this.headers,
      ...config,
    });
  },
  async markAsReImbursed(loanId, config = {}) {
    return await this.$patch({
      url: `${this.$baseurl}/admin/personal/loans/${loanId}/mark-as-reimbursed`,
      headers: this.headers,
      ...config,
    });
  },
};
