export default {
  async all(companyId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/expense/policy/company/${companyId}`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/expense/policy`,
      headers: this.headers,
      ...config,
    });
  },
  async update(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/expense/policy/update`,
      headers: this.headers,
      ...config,
    });
  },
  async attach(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/expense/policy/attach-to-role`,
      headers: this.headers,
      ...config,
    });
  },
  async delete(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/expense/policy/delete`,
      headers: this.headers,
      ...config,
    });
  },
};
