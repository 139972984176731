export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/card/repayment/${this.user.company_id}`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/card/repayment/setup`,
      headers: this.headers,
      ...config,
    });
  },
};
