export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/lenders`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/lenders`,
      headers: this.headers,
      ...config,
    });
  },
  async delete(lenderId, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/admin/lenders/${lenderId}`,
      headers: this.headers,
      ...config,
    });
  },
};
