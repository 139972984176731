export default {
  async referralList(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/referral`,
      headers: this.headers,
      ...config,
    });
  },
  async summary(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/referral/summary`,
      headers: this.headers,
      ...config,
    });
  },
  async walletBalance(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/referral/wallet`,
      headers: this.headers,
      ...config,
    });
  },
  async redeem(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/referral/wallet/redeem`,
      headers: this.headers,
      ...config,
    });
  },
  async generateLink(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/referral/generate-link`,
      headers: this.headers,
      ...config,
    });
  },
};
