export default {
  async getCategories(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/push-notifications/categories`,
      headers: this.headers,
      ...config,
    });
  },
  async deleteNotification(id, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/push-notifications/${id}`,
      headers: this.headers,
      ...config,
    });
  },
  async deleteCategory(id, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/push-notifications/categories/${id}`,
      headers: this.headers,
      ...config,
    });
  },
  async uploadUsers(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/push-notifications/categories/upload-users`,
      headers: this.headers,
      ...config,
    });
  },
  async addNewCategoryWithUsers(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/push-notifications/categories/users`,
      headers: this.headers,
      ...config,
    });
  },
  async addNewCategory(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/push-notifications/categories`,
      headers: this.headers,
      ...config,
    });
  },
  async updateCategoryUsers(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/push-notifications/categories/update-users`,
      headers: this.headers,
      ...config,
    });
  },
  async sendPushNotification(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/push-notifications/send`,
      headers: this.headers,
      ...config,
    });
  },
  async scheduleOrSendTopicBasedNotification(url, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/${url}`,
      headers: this.headers,
      ...config,
    });
  },
  async removeFromCategory(userId, config = {}) {
    return await this.$_delete({
      url: `${
        this.$baseurl
      }/push-notifications/categories/${this.categoryId()}/user/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
};
