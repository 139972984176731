export default {
  async updateAdditionalPhone(userId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/personal/users/${userId}/profile/update`,
      headers: this.headers,
      ...config,
    });
  },
  async orderHistory(userId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/${userId}/order-transactions`,
      headers: this.headers,
      ...config,
    });
  },
  employment: require('./employment').default,
};
