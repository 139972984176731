export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users`,
      headers: this.headers,
      ...config,
    });
  },
  async show(userId, queryParams = {}, config = {}) {
    const query = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/${userId}?${query}`,
      headers: this.headers,
      ...config,
    });
  },
  async search(params = {}, config = {}) {
    const query = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/search?${query}`,
      headers: this.headers,
      ...config,
    });
  },
  async status(status, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/status/${status}`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users`,
      headers: this.headers,
      ...config,
    });
  },
  async createMany(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/create/many`,
      headers: this.headers,
      ...config,
    });
  },
  async approve(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/profile/activate`,
      headers: this.headers,
      ...config,
    });
  },
  async increaseLimit(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/increase-limit`,
      headers: this.headers,
      ...config,
    });
  },
  async markIncreaseLimitAsReviewed(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/users/credit-limits/mark-as-reviewed/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async rejectIncreaseLimit(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/users/credit-limits/reject/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async verify(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/profile/verify`,
      headers: this.headers,
      ...config,
    });
  },
  async delete(userId, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/admin/personal/users/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async deactivate(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/profile/deactivate`,
      headers: this.headers,
      ...config,
    });
  },
  async decline(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/profile/decline`,
      headers: this.headers,
      ...config,
    });
  },
  async uncertain(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/profile/uncertain`,
      headers: this.headers,
      ...config,
    });
  },
  async activateDebitCard(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/profile/activate-debit-card`,
      headers: this.headers,
      ...config,
    });
  },
  async updateStatus(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/status`,
      headers: this.headers,
      ...config,
    });
  },
  async signupStatistics(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/leads/statistics`,
      headers: this.headers,
      ...config,
    });
  },
  async underwritingStatistics(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/underwriting/statistics`,
      headers: this.headers,
      ...config,
    });
  },
  async updateProfile(userId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/personal/users/${userId}/profile`,
      headers: this.headers,
      ...config,
    });
  },
  async blacklistUser(userId, config = {}) {
    return await this.$patch({
      url: `${this.$baseurl}/staff/${userId}/blacklist`,
      headers: this.headers,
      ...config,
    });
  },
  async unblacklistUser(userId, config = {}) {
    return await this.$patch({
      url: `${this.$baseurl}/staff/${userId}/unblacklist`,
      headers: this.headers,
      ...config,
    });
  },
  appealRequests: require('./appealRequests').default,
  assigned: require('./assigned').default,
  bankAccount: require('./bankAccount').default,
  cash: require('./cash').default,
  comments: require('./comments').default,
  creditApplications: require('./creditApplications').default,
  documents: require('./documents').default,
  loanbot: require('./loanbot').default,
  profile: require('./profile').default,
  virtualCards: require('./virtualCards').default,
};
