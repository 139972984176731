export default {
  async loginWithAuthToken(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/login/token`,
      headers: this.headers,
      ...config,
    });
  },
  async updatePassword(config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/auth/v2/update-password`,
      headers: this.headers,
      ...config,
    });
  },
};
