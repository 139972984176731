export default {
  async bills(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/transactions/refundable/bills/cancel`,
      headers: this.headers,
      ...config,
    });
  },
  async transfers(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/transactions/refundable/transfers/cancel`,
      headers: this.headers,
      ...config,
    });
  },
};
