export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants`,
      headers: this.headers,
      ...config,
    });
  },
  async pending(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/pending`,
      headers: this.headers,
      ...config,
    });
  },
  async updateProfile(merchantId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/merchants/update-profile/${merchantId}`,
      headers: this.headers,
      ...config,
    });
  },
  async category(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/industries`,
      headers: this.headers,
      ...config,
    });
  },
  async updateCategory(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/industry/update`,
      headers: this.headers,
      ...config,
    });
  },
  async reorderIndustries(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/industry/sorting`,
      headers: this.headers,
      ...config,
    });
  },
  async settings(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/settings`,
      headers: this.headers,
      ...config,
    });
  },
  async deleteSetting(id, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/admin/merchants/settings/${id}`,
      headers: this.headers,
      ...config,
    });
  },
  async changeFeaturedStatus(merchantId, status, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/merchants/featured/${merchantId}/${status}`,
      headers: this.headers,
      ...config,
    });
  },
  async changeDealStatus(dealId, status, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/settings/${status}/${dealId}`,
      headers: this.headers,
      ...config,
    });
  },
  async deactivatedDeals(merchantId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/settings/deactivated/${merchantId}`,
      headers: this.headers,
      ...config,
    });
  },
  async vendorAccess(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/vendor-access`,
      headers: this.headers,
      ...config,
    });
  },
  async storeVendorAccess(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/vendor-access`,
      headers: this.headers,
      ...config,
    });
  },
  async deleteMerchant(merchantId, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/admin/merchants/merchant/${merchantId}`,
      headers: this.headers,
      ...config,
    });
  },
  async saveConfigurationChanges(merchantId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/merchants/${merchantId}/configurations`,
      headers: this.headers,
      ...config,
    });
  },
  async approveDeviceFinancingOrder(orderId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/device-financing/approve/${orderId}`,
      headers: this.headers,
      ...config,
    });
  },
  async cancelOrder(orderId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/order/${orderId}/cancel`,
      headers: this.headers,
      ...config,
    });
  },
  async viewPaymentReceipt(orderId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/device-financing/payment-receipt/${orderId}`,
      headers: this.headers,
      ...config,
    });
  },
  async addDeviceLockCode(orderId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/device-financing/set-lock-code/${orderId}`,
      headers: this.headers,
      ...config,
    });
  },
  branch: require('./branch').default,
  region: require('./region').default,
  settlements: require('./settlements').default,
  transactions: require('./transactions').default,
  payouts: require('./payouts').default,
};
