export default {
  async requestOtp(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/identity-verification/request-otp`,
      headers: this.headers,
      ...config,
    });
  },
  async verifyOtp(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/identity-verification/verify-otp`,
      headers: this.headers,
      ...config,
    });
  },
};
