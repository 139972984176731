import { path, admin, customer, accountTypes } from './utils/pages_utils';

export default {
  'loanbot-verifications': {
    route: {
      name: 'loanbot-verifications',
      path: path('loanbot-verifications', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'loanbot-verifications' */ '@/views/app/loanbot-verifications/Index.vue'
        ),
      meta: {
        title: 'Loanbot Verifications',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: [
      'credpal:can_view_loanbot_verifications',
      'credpal:can_use_loanbot_verifications',
    ],
  },

  lenders: {
    route: {
      name: 'lenders',
      path: path('lenders', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'lenders-index' */ '@/views/app/lenders/Index.vue'
        ),
      meta: {
        title: 'Dashboard',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_lenders', 'credpal:can_delete_lenders'],
  },
  'lenders-new': {
    route: {
      name: 'lenders-new',
      path: path('lenders/new', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'lenders-new' */ '@/views/app/lenders/New.vue'
        ),
      meta: {
        title: 'Dashboard',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_lenders', 'credpal:can_create_lenders'],
  },

  loans: {
    route: {
      name: 'loans',
      path: path('loans', admin, customer),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'loans-index' */ '@/views/app/loans/all/Index.vue'
        ),
      meta: {
        title: 'All Loans',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loans',
      'company:can_view_loans',
      'personal:can_view_loans',
    ],
  },
  'loans-pending': {
    route: {
      name: 'loans-pending',
      path: path('loans/pending', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'loans-pending' */ '@/views/app/loans/pending/Index.vue'
        ),
      meta: {
        title: 'Pending Loans',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loans',
      'company:can_view_loans',
      'personal:can_view_loans',
    ],
  },
  'loans-requests': {
    route: {
      name: 'loans-requests',
      path: path('loans/requests', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'loans-requests' */ '@/views/app/loans/Requests.vue'
        ),
      meta: {
        title: 'Loan Requests',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_loans', 'credpal:can_approve_loans'],
  },
  'loans-business': {
    route: {
      name: 'loans-business',
      path: path('loans/business', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'loans-business' */ '@/views/app/loans/business/Index.vue'
        ),
      meta: {
        title: 'Loan Requests',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_loans', 'credpal:can_approve_loans'],
  },
  'loan-processor': {
    route: {
      name: 'loan-processor',
      path: path('doc-processor/loan', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'loan-processor' */ '@/views/app/doc-processor/loans/Index.vue'
        ),
      meta: {
        title: 'Users Loan Processor',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  reconciliation: {
    route: {
      name: 'reconciliation',
      path: path('reconciliation', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'reconciliation' */ '@/views/app/reconciliation/Index.vue'
        ),
      meta: {
        title: 'Reconciliation',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  trips: {
    route: {
      name: 'trips',
      path: path('trips', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'reconciliation' */ '@/views/app/trips/Index.vue'
        ),
      meta: {
        title: 'Trips',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },

  /** Credit  */

  'credit-application': {
    route: {
      name: 'credit-application',
      path: `/:accountType(${accountTypes})/credit-application`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'credit-application' */ '@/views/app/credit-application/Index.vue'
        ),
      meta: {
        title: 'Apply For Credit',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'company:can_apply_for_credit',
      'personal:can_apply_for_credit',
    ],
  },
  'companies-credit': {
    route: {
      name: 'companies-credit',
      path: path('companies/credit-applications', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'companies-credit' */ '@/views/app/companies/Credit.vue'
        ),
      meta: {
        title: 'Credit Applications',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_credit_applications',
      'credpal:can_approve_credit_applications',
      'credpal:can_decline_credit_applications',
    ],
  },

}