export default {
  async markAsPaid(secureCardId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/secure-cards/mark-as-paid/${secureCardId}`,
      headers: this.headers,
      ...config,
    });
  },
  async viewReceipt(secureCardId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/secure-cards/show-receipt-file/${secureCardId}`,
      headers: this.headers,
      ...config,
    });
  },
};
