import { path, accountTypes, admin } from './utils/pages_utils'
export default {
  'referral-page': {
    route: {
      name: 'referral-page',
      path: path('affiliate/referrals', admin),
      parent: 'app',
      component: () => import('@/views/app/affiliate/referral/Index.vue'),
      meta: {
        title: 'Affiliate Users',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },

  'affiliate-view': {
    route: {
      name: 'affiliate-view',
      path: `/:accountType(${accountTypes})/affiliates/:userId`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'staff-index' */ '@/views/app/affiliate/view/Index.vue'
        ),
      meta: {
        title: 'View Affiliate',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_staff_profile',
      'company:can_view_staff_profile',
    ],
  },
  'affiliate-settlement': {
    route: {
      name: 'affiliate-settlement',
      path: `/:accountType(${accountTypes})/affiliate/settlements`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'settlements-index' */ '@/views/app/affiliate/settlements/Index.vue'
        ),
      meta: {
        title: 'Settlements',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_settlements',
      'credpal:can_delete_settlements',
    ],
  },
  'affiliate-statistics': {
    route: {
      name: 'affiliate-statistics',
      path: path('affiliate/statistics', admin),
      parent: 'app',
      component: () =>
        import('@/views/app/affiliate/statistics/Statistics.vue'),
      meta: {
        title: 'Affiliate Statistics',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'affiliate-leads': {
    route: {
      name: 'affiliate-leads',
      path: path('affiliate/leads', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'referees' */ '@/views/app/affiliate/leads/AllLeads.vue'
        ),
      meta: {
        title: 'Affiliate Leads',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
    ],
    permissions: ['*'],
  },
  'redirect-user': {
    route: {
      name: 'redirect-user',
      path: '/a/m',
      parent: 'root',
      component: () =>
        import(
          /* webpackChunkName: 'register' */ '@/views/app/affiliate/Redirect.vue'
        ),
      meta: {
        title: 'Redirect',
      },
    },
    middlewares: [],
    permissions: ['*'],
  },
  'share-and-earn-redirect': {
    route: {
      name: 'share-and-earn-redirect',
      path: '/r/c/:referralCode',
      parent: 'root',
      component: () => import(/**/ '@/views/app/share-and-earn/Redirect.vue'),
      meta: {
        title: 'Redirect',
      },
    },
    middlewares: [],
    permissions: ['*'],
  },
  referrers: {
    route: {
      name: 'referrers',
      path: path('referrers', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'referrers' */ '@/views/app/referrers/list/Index.vue'
        ),
      meta: {
        title: 'Referees',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
    ],
    permissions: ['credpal:can_view_staff'],
  },
  referees: {
    route: {
      name: 'referees',
      path: path('referees', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'referees' */ '@/views/app/referees/list/Index.vue'
        ),
      meta: {
        title: 'Referrers',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
    ],
    permissions: ['credpal:can_view_staff'],
  },
};