export default {
  async getStatementAnalysis(userId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/loanbot/statement-analysis/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async downloadStatement(userId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/loanbot/statement-pdf/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async getCreditSummary(userId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/loanbot/credit-summary/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async instantApproval(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loanbot/verifications/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async updateAnalyses(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/loanbot/update-analyses/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async getPngMeData(phoneNo, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/loanbot/pngme-data/${phoneNo}`,
      headers: this.headers,
      ...config,
    });
  },
};
