import { path, admin, customer, accountTypes } from './utils/pages_utils';

export default {
    repayments: {
        route: {
          name: 'repayments',
          path: path('repayments', admin, customer),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'repayments-index' */ '@/views/app/repayments/all/Index.vue'
            ),
          meta: {
            title: 'Repayments',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'credpal:can_view_repayments',
          'credpal:can_view_loan_repayments',
          'credpal:can_delete_repayments',
          'personal:can_view_repayments',
          'personal:can_pay_repayments',
        ],
      },
      'advancly-loans': {
        route: {
          name: 'advancly-loans',
          path: path('advancly-loans', admin, customer),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'repayments-advancly-loans' */ '@/views/app/repayments/advancly-loans/Index.vue'
            ),
          meta: {
            title: 'Advancly Loans',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'credpal:can_view_repayments',
          'credpal:can_view_loan_repayments',
          'credpal:can_delete_repayments',
          'personal:can_view_repayments',
          'personal:can_pay_repayments',
        ],
      },
      'payments-credit-cards': {
        route: {
          name: 'payments-credit-cards',
          path: path('repayments/credit-cards/payments', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'repayments-credit-cards' */ '@/views/app/repayments/credit-cards/Index.vue'
            ),
          meta: {
            title: 'Credit Card Payments',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'credpal:can_view_repayments',
          'credpal:can_delete_repayments',
        ],
      },
      'repayments-credit-cards': {
        route: {
          name: 'repayments-credit-cards',
          path: path('repayments/credit-cards', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'repayments-credit-cards' */ '@/views/app/repayments/credit-cards-repayments/Super.vue'
            ),
          meta: {
            title: 'Credit Card Repayments',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'credpal:can_view_repayments',
          'credpal:can_view_credit_card_repayments',
          'credpal:can_delete_repayments',
        ],
      },
      'repayments-business': {
        route: {
          name: 'repayments-business',
          path: path('repayments/business', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'repayments-business' */ '@/views/app/repayments/business/Index.vue'
            ),
          meta: {
            title: 'Repayments',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'credpal:can_view_repayments',
          'credpal:can_delete_repayments',
        ],
      },
      'settle-repayments': {
        route: {
          name: 'settle-repayments',
          path: path('repayments/settle', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'settle-repayments' */ '@/views/app/repayments/settle-repayments/Index.vue'
            ),
          meta: {
            title: 'Repayments',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['credpal:can_settle_repayment'],
      },
      'repayments-statistics': {
        route: {
          name: 'repayments-statistics',
          path: path('repayments/statistics', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'repayments-statistics' */ '@/views/app/repayments/statistics/Index.vue'
            ),
          meta: {
            title: 'Repayments',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'credpal:can_view_repayments',
          'credpal:can_delete_repayments',
        ],
      },
      'repayments-defaulters': {
        route: {
          name: 'repayments-defaulters',
          path: path('repayments/defaulters', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'repayments-defaulters' */ '@/views/app/repayments/defaulters/Index.vue'
            ),
          meta: {
            title: 'Loan Defaulters',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'credpal:can_view_loans',
          'company:can_view_loans',
          'personal:can_view_loans',
        ],
      },
      'repayment-cards': {
        route: {
          name: 'repayment-cards',
          path: `/:accountType(${accountTypes})/repayment-cards`,
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'repayment-cards' */ '@/views/app/repayment-cards/Index.vue'
            ),
          meta: {
            title: 'Repayment Cards',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'credpal:can_view_repayment_cards',
          'credpal:can_create_repayment_cards',
          'credpal:can_update_repayment_cards',
          'credpal:can_delete_repayment_cards',
          'personal:can_view_repayment_cards',
          'personal:can_create_repayment_cards',
          'personal:can_update_repayment_cards',
          'personal:can_delete_repayment_cards',
        ],
      },
  'tangerine-loan-repayments': {
    route: {
      name: 'tangerine-loan-repayments',
      path: path('repayments/personal/loans/tangerine', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'tangerine-loan-repayments' */ '@/views/app/repayments/lenders/loans/tangerineLife/Index.vue'
        ),
      meta: {
        title: 'Tangerine Loan Repayments',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loan_disbursements',
      'credpal:can_disburse_loans',
      'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements',
    ],
  },
  'tangerine-credit-card-repayments': {
    route: {
      name: 'tangerine-credit-card-repayments',
      path: path('repayments/personal/credit-card/tangerine', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'tangerine-loan-repayments' */ '@/views/app/repayments/lenders/credit-card/tangerineLife/Index.vue'
        ),
      meta: {
        title: 'Tangerine Credit Card Repayments',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_loan_disbursements',
      'credpal:can_disburse_loans',
      'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements',
    ],
  }
}