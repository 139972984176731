export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/activities/${this.user.company_id}`,
      headers: this.headers,
      ...config,
    });
  },
  async recent(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/activities/recent/${this.user.company_id}`,
      headers: this.headers,
      ...config,
    });
  },
};
