export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/cards/user/${this.user.id}`,
      headers: this.headers,
      ...config,
    });
  },
  async requestPin(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/card/pin/request`,
      headers: this.headers,
      ...config,
    });
  },
  async activate(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/creditCard/activate`,
      headers: this.headers,
      ...config,
    });
  },
};
