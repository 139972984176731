import Vue from 'vue';

// Forms
Vue.component('create-expense-category', () =>
  import('./forms/create/ExpenseCategory.vue')
);
Vue.component('create-expense-sub-category', () =>
  import('./forms/create/ExpenseSubCategory.vue')
);
Vue.component('debit-cash-wallet', () =>
  import('./forms/DebitCashWallet.vue')
);
Vue.component('update-cash-wallet-limit', () =>
  import('./forms/UpdateCashWalletLimit.vue')
);
Vue.component('expense-request', () => import('./forms/expenses/Request.vue'));
Vue.component('expense-report', () => import('./forms/expenses/Report.vue'));
Vue.component('express', () => import('./forms/Express.vue'));
Vue.component('korapay', () => import('./forms/Korapay'));
Vue.component('monnify', () => import('./forms/Monnify.vue'));
Vue.component('mono', () => import('./forms/Mono'));
Vue.component('okra', () => import('./forms/Okra'));
Vue.component('onboarding', () => import('./forms/Onboarding.vue'));
Vue.component('payment-credit-card-balance', () =>
  import('./forms/payments/CreditCardBalance.vue')
);
Vue.component('payment-settlement', () =>
  import('./forms/payments/Settlement.vue')
);
Vue.component('payment-repayment', () =>
  import('./forms/payments/Repayment.vue')
);
Vue.component('paystack', () => import('./forms/Paystack.vue'));
Vue.component('personal-transfer', () =>
  import('./forms/transfers/PersonalTransfer.vue')
);
Vue.component('repayment-cards', () => import('./forms/RepaymentCards.vue'));
Vue.component('transfers-request', () =>
  import('./forms/transfers/RequestTransfer.vue')
);
Vue.component('user-onboarding', () => import('./user-onboarding/Index.vue'));
Vue.component('verification-statement', () =>
  import('./forms/verification/statement/Index.vue')
);

// Inputs
Vue.component('checkbox', () => import('./forms/inputs/Checkbox.vue'));
Vue.component('custom-input', () => import('./forms/inputs/Input.vue'));
Vue.component('custom-select', () => import('./forms/inputs/Select.vue'));
Vue.component('datepicker', () => import('vuejs-datepicker'));
Vue.component('dropzone', () => import('./forms/inputs/DropZone.vue'));
Vue.component('form-group', () => import('./forms/inputs/FormGroup2.vue'));
Vue.component('form-group-old', () => import('./forms/inputs/FormGroup.vue'));
Vue.component('input-bank', () => import('./forms/inputs/Banks.vue'));
Vue.component('input-date', () => import('./forms/inputs/Date.vue'));
Vue.component('input-date-of-birth', () =>
  import('./forms/inputs/DateOfBirth.vue')
);
Vue.component('input-employment-status', () =>
  import('./forms/inputs/EmploymentStatus.vue')
);
Vue.component('input-employment-type', () =>
  import('./forms/inputs/EmploymentType.vue')
);
Vue.component('input-gender', () => import('./forms/inputs/Gender.vue'));
Vue.component('input-id-type', () => import('./forms/inputs/IdType.vue'));
Vue.component('input-marital-status', () =>
  import('./forms/inputs/MaritalStatus.vue')
);
Vue.component('input-money', () => import('./forms/inputs/Money.vue'));
Vue.component('input-password', () => import('./forms/inputs/Password.vue'));
Vue.component('input-salary-day', () => import('./forms/inputs/SalaryDay.vue'));
Vue.component('input-split', require('./forms/inputs/SplitInput.vue').default);
Vue.component('input-textarea', () => import('./forms/inputs/Textarea.vue'));
Vue.component('money', () => import('v-money').Money);
Vue.component('search-form', () => import('./forms/inputs/SearchForm.vue'));

// Presentation
Vue.component('badge-loan-status', () =>
  import('./presentation/badeges/LoanStatus.vue')
);
Vue.component('custom-bar-chart', () => import('./presentation/BarChart.vue'));
Vue.component('custom-slider', () => import('./presentation/CustomSlider.vue'));
Vue.component('personal-custom-slider', () =>
  import('./presentation/PersonalCustomSlider.vue')
);
Vue.component('datatable', require('./presentation/Table.vue').default);
Vue.component('doughnut-chart', () =>
  import('./presentation/DoughnutChart.vue')
);
Vue.component('draggable', () => import('vuedraggable'));
Vue.component('modal', require('./presentation/Modal.vue').default);
Vue.component('modal2', () => import('./presentation/Modal2.vue'));
Vue.component('modal22', () => import('./presentation/Modal22.vue'));
Vue.component('modal3', () => import('./presentation/Modal3.vue'));
Vue.component('modal4', () => import('./presentation/Modal4.vue'));
Vue.component('modal5', () => import('./presentation/Modal5.vue'));
Vue.component('modals-loan', () => import('./presentation/modals/Loan.vue'));
Vue.component('modals-merchant', () =>
  import('./presentation/modals/Merchant.vue')
);
Vue.component('modals-user', () => import('./presentation/modals/User.vue'));
Vue.component('offer-letter', () => import('./presentation/OfferLetter.vue'));
Vue.component('statistics', () => import('./presentation/Statistics.vue'));
Vue.component('stat', () => import('./presentation/Stat.vue'));
Vue.component('tabs', () => import('./presentation/Tabs.vue'));
Vue.component('tool-tip', () => import('./presentation/Tooltip.vue'));

// Cards
Vue.component('credit-card', () => import('./cards/CreditCard.vue'));
Vue.component('credit-card-2', () => import('./cards/CreditCard2.vue'));
Vue.component('credit-card-3', () => import('./cards/CreditCard3.vue'));
Vue.component('credit-card-4', () => import('./cards/CreditCard4.vue'));
Vue.component('register-credit-card', () => import('./cards/Register.vue'));

// Loaders
Vue.component('credpal-loader', require('./loaders/CredPal.vue').default);
Vue.component('loader', () => import('./loaders/Loader.vue'));
Vue.component('sm-loader', () => import('./loaders/SmallLoader.vue'));
Vue.component('sm-loader-white', () =>
  import('./loaders/SmallLoaderWhite.vue')
);
Vue.component('skeleton-loader', () => import('./loaders/SkeletonLoader.vue'));

// Logos
Vue.component('logo', require('./logo/Blue.vue').default);
Vue.component('logo-icon', require('./logo/Icon.vue').default);
Vue.component('logo-round', () => import('./logo/Round.vue'));

// Misc
Vue.component('overlay', require('./Overlay.vue').default);
Vue.component('vector', require('./Vector.vue').default);

//Marketplace
Vue.component('marketplace-category', () => import('./marketplace/Category'));
Vue.component('marketplace-deal', () => import('./marketplace/Deal'));
