export default {
  async paymentMethod(orderAmount, merchantId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/merchant/payment-summary/v2/${merchantId}/${orderAmount}`,
      headers: this.headers,
      ...config,
    });
  },
  async oneTimeSummary(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/merchant/payment-summary/onetime`,
      headers: this.headers,
      ...config,
    });
  },
  async spreadPaymentSummary(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/merchant/payment-summary/spread-payment`,
      headers: this.headers,
      ...config,
    });
  },
};
