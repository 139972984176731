export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/all/orders`,
      headers: this.headers,
      ...config,
    });
  },
  async deleteIncompleteOrder(orderId, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/admin/merchants/incomplete-orders/${orderId}`,
      headers: this.headers,
      ...config,
    });
  },
  async markOrderAsPaid(orderId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/order/mark-as-paid/${orderId}`,
      headers: this.headers,
      ...config,
    });
  },
  async merchantOrders(merchantId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/merchants/${merchantId}/orders`,
      headers: this.headers,
      ...config,
    });
  },
};
