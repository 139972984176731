export default {
  async bankStatement(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/documents/bank-statement`,
      headers: this.headers,
      ...config,
    });
  },
  async blankBankStatement(userId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/bank-statement/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async govtId(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/documents/govt-id`,
      headers: this.headers,
      ...config,
    });
  },
  async workId(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/documents/work-id`,
      headers: this.headers,
      ...config,
    });
  },
  async creditReport(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/documents/credit-report`,
      headers: this.headers,
      ...config,
    });
  },
  async custom(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/documents/custom`,
      headers: this.headers,
      ...config,
    });
  },
  async request(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/documents/request`,
      headers: this.headers,
      ...config,
    });
  },
  async invalidate(userId, documentId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/documents/${documentId}/invalidate`,
      headers: this.headers,
      ...config,
    });
  },
  async documentEvent(event, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/documents-event/${event}`,
      headers: this.headers,
      ...config,
    });
  },
  async confirmMbs(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/documents/confirm-mbs`,
      headers: this.headers,
      ...config,
    });
  },
};
