export default {
  async redemption(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/transaction/vault/statement-credit`,
      headers: this.headers,
      ...config,
    });
  },
  async redeemWallet(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/transaction/redeem-wallet`,
      headers: this.headers,
      ...config,
    });
  },
  async snapshot(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/wallet-snapshot`,
      headers: this.headers,
      ...config,
    });
  },
  async balance(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/vault-balances`,
      headers: this.headers,
      ...config,
    });
  },
  async walletBalance(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/wallet`,
      headers: this.headers,
      ...config,
    });
  },
  transaction: require('./transaction').default,
};
