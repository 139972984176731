import { path, admin, customer } from './utils/pages_utils';

export default {
  'share-and-earn': {
    route: {
      name: 'share-and-earn',
      path: path('share-and-earn', admin, customer),
      parent: 'app',
      component: () => import(/**/ '@/views/app/share-and-earn/Index'),
      meta: {
        title: 'Share and Earn',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },

  'bolt-users': {
    route: {
      name: 'bolt-users',
      path: path('bolt-users', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'bolt-users' */ '@/views/app/bolt/Users.vue'
        ),
      meta: {
        title: 'Bolt Users',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_bolt_users'],
  },
  'bolt-rides': {
    route: {
      name: 'bolt-rides',
      path: path('bolt-rides', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'bolt-rides' */ '@/views/app/bolt/Rides.vue'
        ),
      meta: {
        title: 'Bolt Rides',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_bolt_rides'],
  },
  'promos-airtel': {
    route: {
      name: 'promos-airtel',
      path: path('promos-airtel', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'promos-airtel' */ '@/views/app/promos/airtel/Index.vue'
        ),
      meta: {
        title: 'Airtel Promotions',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_manage_airtel_promos'],
  },

}