export default {
  async categories(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/flutterwave/bill-categories`,
      headers: this.headers,
      ...config,
    });
  },
  async getService(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/flutterwave/bill-payment`,
      headers: this.headers,
      ...config,
    });
  },
};
