export default {
  async bills(config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/transactions/refundable/bills`,
      headers: this.headers,
      ...config,
    });
  },
  async transfers(config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/transactions/refundable/transfers`,
      headers: this.headers,
      ...config,
    });
  },
};
