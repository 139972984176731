import Vue from 'vue';
import { mapState } from 'vuex';

Vue.mixin({
  computed: {
    ...mapState('session', ['user', 'loggedIn']),

    accountType() {
      return this.$route.params.accountType;
    },
    appMode() {
      switch (this.accountType) {
        case process.env.VUE_APP_ADMIN_PATH:
          return 'super';
        case process.env.VUE_APP_CORPORATE_PATH:
          return 'company';
        case process.env.VUE_APP_CUSTOMER_PATH:
          return 'staff';
        default:
          return null;
      }
    },
    firstName() {
      if (!this.user) {
        return null;
      }
      const name = this.user.name;
      return name.split(' ')[0];
    },
    firstLetter() {
      return this.firstName ? this.firstName[0] : null;
    },
    isCredpalAdmin() {
      return this.$store.state.session.roles?.find((role) =>
        role.slug.match(/^super_/)
      );
    },
    isCompanyAdmin() {
      return this.$store.state.session.roles?.find(
        (role) =>
          role.slug.match(/^company_/) &&
          !role.slug.match(
            /company_staff|company_department_head|company_unit_head/
          )
      );
    },
    isCompanyStaff() {
      return this.$store.state.session.roles?.find((role) =>
        role.slug.match(
          /company_staff|company_department_head|company_unit_head/
        )
      );
    },
    isCompanyFinanceManager() {
      return this.$store.state.session.roles?.find(
        (role) => role.slug === 'company_finance_manager'
      );
    },
    isCompanyHR() {
      return this.$store.state.session.roles?.find(
        (role) => role.slug === 'company_hr'
      );
    },
    isSuperAdmin() {
      return !!this.$store.state.session.roles?.find(
        (role) => role.slug === 'super_admin'
      );
    },
    isSuperSalesAgent() {
      return this.$store.state.session.roles?.find(
        (role) => role.slug === 'super_sales_agent'
      );
    },
    isSuperRisk() {
      return !!this.$store.state.session.roles?.find(
        (role) => role.slug === 'super_risk'
      );
    },
    lastName() {
      if (!this.user) {
        return null;
      }
      return this.user?.last_name;
    },
    expressVerificationMethod() {
      return this.user?.profile?.verify_method === 'express';
    },
    bankStatementUploaded() {
      return this.user?.documents?.find(
        (document) => document.type == 'bank_statement'
      );
    },
    isProfileActivated() {
      return this.user?.profile?.status === 'activated';
    },
    timeOfDay() {
      const currentHour = new Date().getHours();

      if (currentHour >= 0 && currentHour < 12) {
        return 'Morning';
      }

      if (currentHour >= 12 && currentHour < 18) {
        return 'Afternoon';
      }

      return 'Evening';
    },
    ENV_VUE_APP_ADMIN_PATH() {
      return process.env.VUE_APP_ADMIN_PATH;
    },
    ENV_VUE_APP_CORPORATE_PATH() {
      return process.env.VUE_APP_CORPORATE_PATH;
    },
    ENV_VUE_APP_CUSTOMER_PATH() {
      return process.env.VUE_APP_CUSTOMER_PATH;
    },
    notifications() {
      return this.$store.state.notifications.unreadNotifications;
    },
    notification() {
      return this.$store.state.notifications.singleNotification;
    },
  },
  methods: {
    canAccessAny(routeNames) {
      return (
        routeNames.filter((routeName) => this.canAccess(routeName)).length > 0
      );
    },
    resumeOnboarding() {
      this.$root.$emit('open-onboarding');
    },
    validateUser(param, user = null) {
      user = user || this.user;
      let props;

      switch (param) {
        case 'approval':
          return user?.profile?.status === 'activated';
        case 'bank_statement':
          return user?.documents?.find(
            (doc) =>
              doc.type === 'bank_statement' ||
              doc.url.match(/bank_statement|mbs|mobile|mono|okra|ussd/)
          );
        case 'bio':
          return (
            (user?.profile?.bvn || user?.profile?.nin) &&
            user?.profile?.date_of_birth &&
            user?.profile?.employment_status &&
            user?.profile?.salary &&
            user?.profile?.salary_day &&
            user?.profile?.account_no &&
            user?.profile?.bank_name
          );
        case 'blacklisted':
          return user?.is_blacklisted;
        case 'bvn':
          return (
            user?.profile?.bvn &&
            (user?.bvn_verified_at || user?.bvn_bypassed_at)
          );
        case 'bvn_verified':
          return user?.profile?.bvn && user?.bvn_verified_at;
        case 'card_request':
          return user?.card_request;
        case 'date_of_birth':
          return user?.profile?.date_of_birth;
        case 'declined':
        case 'deactivated':
          return user?.profile?.status?.match(/declined|deactivated/);
        case 'documents':
          return (
            (this.validateUser('work_id', user) ||
              this.validateUser('govt_id', user)) &&
            this.validateUser('bank_statement') &&
            this.validateUser('bvn', user)
          );
        case 'employment':
          if (user?.profile?.employment_status == 'self_employed') {
            return (
              user?.profile?.employer &&
              user?.profile?.company_industry &&
              user?.profile?.company_description &&
              user?.profile?.company_start_month &&
              user?.profile?.company_start_year &&
              user?.profile?.company_monthly_income &&
              user?.profile?.company_monthly_profit &&
              user?.profile?.salary &&
              user?.profile?.education
            );
          }
          return (
            (user?.profile?.employer || user?.company_id) &&
            user?.profile?.salary
          );
        case 'express':
          return user?.profile?.verify_method?.match(/express/i);
        case 'govt_id':
          return (
            user?.documents?.find((doc) => doc.type === 'govt_id') ||
            this.validateUser('nin', user)
          );
        case 'identity':
          return (
            this.validateUser('bvn_verified', user) ||
            this.validateUser('nin_verified', user)
          );
        case 'nin':
          return (
            user?.profile?.nin &&
            (user?.nin_verified_at || user?.nin_bypassed_at)
          );
        case 'nin_verified':
          return user?.profile?.nin && user?.nin_verified_at;
        case 'onboarding':
          return (
            // User Is not declined or deactivated.
            !this.validateUser('declined', user) &&
            // User has verified their identity.
            this.validateUser('bvn', user) &&
            this.validateUser('date_of_birth', user) &&
            // User has undergone instant approval process provided they qualify.
            (this.validateUser(
              'onboarding.instant_approval_qualification',
              user
            )
              ? this.validateUser('onboarding.instant_approval', user)
              : true) &&
            // User has completed account verification (work email | bank statement).
            this.validateUser('onboarding.account_verification', user) &&
            // User has updated their profile.
            this.validateUser('onboarding.form2', user) &&
            // User has verified their nin.
            this.validateUser('identity', user) &&
            // User has provided necessary identifiation while awaiting manual approval.
            (!this.validateUser('approval', user)
              ? !this.validateUser('govt_id', user) ||
                !this.validateUser('work_id', user)
              : true) &&
            // User has been approved either manually or automatically.
            this.validateUser('approval', user) &&
            // User has made a card request.
            this.validateUser('card_request', user)
          );
        case 'onboarding.account_verification':
          return (
            this.validateUser('work_email', user) ||
            this.validateUser('bank_statement', user) ||
            this.validateUser('self_employed', user)
          );
        case 'onboarding.brand_new':
          return (
            !this.validateUser('bvn', user) &&
            !this.validateUser('date_of_birth', user) &&
            !this.validateUser('onboarding.account_verification', user) &&
            !this.validateUser('onboarding.form2', user) &&
            !this.validateUser('nin', user) &&
            !this.validateUser('govt_id', user) &&
            !this.validateUser('work_id', user) &&
            !this.validateUser('approval', user) &&
            !this.validateUser('card_request', user)
          );
        case 'onboarding.form2':
          props = [
            'profile.marital_status',
            'profile.gender',
            'profile.address',
            'profile.employment_status',
            'profile.employer',
            'profile.salary',
            'profile.salary_day',
            'profile.account_no|profile.trial_account_no',
            'profile.bank_name|profile.trial_bank_code',
          ];

          if (user?.profile?.employment_status === 'employed') {
            props = [
              ...props,
              'profile.position',
              'profile.emp_type',
              'profile.company_address',
            ];
          }
          if (user?.profile?.employment_status === 'self_employed') {
            props = [
              ...props,
              'profile.company_industry',
              'profile.company_description',
              'profile.company_start_year',
              'profile.company_start_month',
              'profile.company_monthly_income',
              'profile.company_monthly_profit',
            ];
          }
          return props.every((property) => {
            return property.split('|').some((prop) => {
              return prop
                .split('.')
                .reduce((parent, child) => parent?.[child], user);
            });
          });
        case 'onboarding.instant_approval_qualification': {
          return (
            this.validateUser('bvn', user) &&
            this.validateUser('date_of_birth', user) &&
            user?.profile?.salary &&
            !this.validateUser('approval', user)
          );
        }
        case 'onboarding.instant_approval':
          return !!user?.loanbot_status;
        case 'self_employed':
          return user?.profile?.employment_status === 'self_employed';
        case 'work_email':
          return user?.official_email && user?.work_email_verified_at;
        case 'work_email_trial':
          return user?.profile?.trial_work_email;
        case 'work_id':
          return (
            user?.documents?.find((doc) => doc.type === 'work_id') ||
            user?.profile?.employment_status !== 'employed'
          );
        default:
          // statements_def
          break;
      }
    },
    transactionStatusBadge(status) {
      const badge = (color, text) =>
        `<div class="badge badge-${color}-soft-outline">${text}</div>`;

        switch (status) {
          case 'success':
            return badge('green', 'Success');
          case 'refunded':
          case 'reversed':
          case 'reverted':
            return badge('red', 'Refunded');
          case 'failed':
            return badge('red', 'Failed');
          case 'error':
            return badge('red', 'Error');
          case 'pending':
          default:
            return badge('gray', 'Pending');
        }
    },
    statusBadge(status, user = null) {
      if (user?.status === 'processed') {
        status = user?.profile?.status || 'approved';
        if (user?.profile?.status === 'activated') status = 'approved';
      }

      const badge = (color, text) =>
        `<div class="badge badge-${color}-soft-outline">${text}</div>`;

      switch (status) {
        case 'processing':
          return badge('blue', 'Processing');
        case 'doc-processor':
          return badge('blue', 'Doc-Processor');
        case 'accepted':
          return badge('green', 'Accepted');
        case 'pre-approved':
          return badge('green', 'Pre-Approved');
        case 'verified':
          return badge('green', 'Processing:Verified');
        case 'approved':
          return badge('green', 'Approved');
        case 'activated':
          return badge('green', 'Activated');
        case 'success':
          return badge('green', 'Success');
        case 'refunded':
        case 'reversed':
        case 'reverted':
          return badge('red', 'Refunded');
        case 'rejected':
          return badge('red', 'Rejected');
        case 'failed':
          return badge('red', 'Failed');
        case 'error':
          return badge('red', 'Error');
        case 'declined':
        case 'deactivated':
          return badge('red', 'Declined');
        case 'incomplete-1':
          return badge('orange', 'Incomplete 1');
        case 'pending':
          return badge('gray', 'Pending');
        case 'incomplete-0':
        default:
          return badge('gray', 'Incomplete 0');
      }
    },
    profileBadge(status) {
      switch (status) {
        case 'pending':
          return `<div class="badge badge-orange-soft-outline">
            Pending
          </div>`;
        case 'verified':
          return `<div class="badge badge-blue-soft-outline">
            Verified
          </div>`;
        case 'pre-approved':
          return `<div class="badge badge-green-soft-outline">
            Pre-Approved
          </div>`;
        case 'activated':
          return `<div class="badge badge-green-soft-outline">
            Activated
          </div>`;
        case 'declined':
          return `<div class="badge badge-red-soft-outline">
            Declined
          </div>`;
        case 'deactivated':
          return `<div class="badge badge-red-soft-outline">
            Deactivated
          </div>`;
        default:
          return null;
      }
    },
    progressIcons(user = null) {
      user = user || this.user;
      const allCompleted =
        this.validateUser('employment', user) &&
        this.validateUser('documents', user) &&
        this.validateUser('bio', user) &&
        user?.profile?.verify_method?.match(/express|manual/i);

      return `
        <div class="border ${
          allCompleted ? 'border-green-500' : 'border-blue-200'
        } inline-flex items-center rounded-sm py-2 pl-3 pr-1">
          ${
            this.validateUser('employment', user)
              ? '<span class="inline-block lni lni-briefcase mr-3 text-lg text-green-600"></span>'
              : '<span class="inline-block lni lni-briefcase mr-3 text-lg text-gray-500"></span>'
          }
          ${
            this.validateUser('documents', user)
              ? '<span class="inline-block lni lni-files mr-3 text-lg text-green-600"></span>'
              : '<span class="inline-block lni lni-files mr-3 text-lg text-gray-500"></span>'
          }
          ${
            this.validateUser('bio', user)
              ? '<span class="inline-block lni lni-checkmark-circle mr-3 text-lg text-green-600"></span>'
              : '<span class="inline-block lni lni-checkmark-circle mr-3 text-lg text-gray-500"></span>'
          }
          ${this.verifyMethod(user)}
        </div>
      `;
    },
    verifyMethod(user) {
      if (user && user.profile && user.profile.verify_method) {
        switch (user.profile.verify_method) {
          case 'express':
            return `<span class="inline-block mr-3" style="width: 18px; height: 18px;"><img src="/Express.svg" style="margin-top: 2px" /><span>`;
          case 'manual':
            return `<span class="inline-block mr-3" style="width: 18px; height: 18px;"><img src="/Bank-Statement.svg" style="margin-top: 2px" /><span>`;
          default:
            return ``;
        }
      }
      return '';
    },
    assignedAdmin(user = null, type = 'all') {
      user = user || this.user;

      let admins = user?.admins;

      if (!admins?.length) {
        return 'N/A';
      }

      switch (type) {
        case 'risk':
          admins = admins.filter((admin) =>
            admin.roles?.find((role) =>
              role.slug.match(/super_risk|super_admin/)
            )
          );
          break;
        case 'sales':
          admins = admins.filter((admin) =>
            admin.roles?.find((role) =>
              role.slug.match(/super_sales_agent|super_support|super_admin/)
            )
          );
          break;
      }

      if (!admins?.length) {
        return 'N/A';
      }

      const admin = [...admins].pop();
      const name = `${admin.name} ${admin.last_name}`;
      const assignedToMore = admins.length > 1;

      switch (admin?.pivot?.status) {
        case 'attended':
          return `
          <div class="text-sm">${name}${
            assignedToMore ? ` & ${admins.length - 1} more` : ''
          }</div>
          <div class="text-xs text-green-700 flex items-center">
            <ion-icon name="checkmark-done-outline" class="mr-2"></ion-icon>
            <span style="margin-top: 2px">Attended</span>
          </div>
          `;
        case 'contacted':
          return `
          <div class="text-sm">${name}${
            assignedToMore ? ` & ${admins.length - 1} more` : ''
          }</div>
          <div class="text-xs text-blue-700 flex items-center">
            <ion-icon name="checkmark-outline" class="mr-2"></ion-icon>
            <span style="margin-top: 2px">Contacted</span>
          </div>
          `;
        case 'pending':
        default:
          return `
          <div class="text-sm">${name}${
            assignedToMore ? ` & ${admins.length - 1} more` : ''
          }</div>
          <div class="text-xs text-orange-700 flex items-center">
            <ion-icon name="remove-circle-outline" class="mr-2"></ion-icon>
            <span style="margin-top: 2px">Pending</span>
          </div>
          `;
      }
    },
    userProfile({ user }) {
      return this.$router.push({
        name: 'staff-view',
        params: {
          staffId: user?.id,
        },
      });
    },
    unreadNotifications: function () {
      if (this.loggedIn) {
        this.$get({
          url: `${this.$baseurl}/notification/unread`,
          headers: this.headers,
          success: (response) => {
            this.$store.commit('notifications/getUnread', response.data.data);
          },
          error: (error) => {
            console.log(error);
          },
        });
      }
    },
    readNotifications: function () {
      this.$get({
        url: `${this.$baseurl}/notification/read`,
        headers: this.headers,
        success: (response) => {
          this.$store.commit('notifications/getRead', response.data.data);
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
  },
});
