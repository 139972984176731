export default {
  async all(companyId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/expense/category/sub-category/${companyId}`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/expense/category/sub-category`,
      headers: this.headers,
      ...config,
    });
  },
};
