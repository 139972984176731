export default {
  async getActivePlan(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/plan/unique-plan/${this.user.company_id}`,
      headers: this.headers,
      ...config,
    });
  },
  async getPlans(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/wallet/fund-debit-wallet`,
      headers: this.headers,
      ...config,
    });
  },
  async subscribe(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/wallet/fund-debit-wallet`,
      headers: this.headers,
      ...config,
    });
  },
};
