export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/cards`,
      headers: this.headers,
      ...config,
    });
  },
  async requests(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/cards/all`,
      headers: this.headers,
      ...config,
    });
  },
  async approve(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/card/approve`,
      headers: this.headers,
      ...config,
    });
  },
  async decline(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/card/decline`,
      headers: this.headers,
      ...config,
    });
  },
  async repayments(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/cards/repayments`,
      headers: this.headers,
      ...config,
    });
  },
  async chargeUser(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/charge`,
      headers: this.headers,
      ...config,
    });
  },
  async markCardAsPaid(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/repayments/initiate/mark`,
      headers: this.headers,
      ...config,
    });
  },
  async markCardAsUnPaid(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/repayments/terminate/mark`,
      headers: this.headers,
      ...config,
    });
  },
  async settleRepayment(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/repayments/finalize/mark`,
      headers: this.headers,
      ...config,
    });
  },
  async viewReceipt(receiptId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/repayments/receipts/${receiptId}`,
      headers: this.headers,
      ...config,
    });
  },
  async addCard(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/user/card/activation`,
      headers: this.headers,
      ...config,
    });
  },
  statements: require('./statements').default,
};
