export default {
  async createAccount(config = {}) {
    return this.$post({
      url: `${this.$baseurl}/register-process/v2/register`,
      headers: this.headers,
      ...config,
    });
  },
  async business(config = {}) {
    return this.$post({
      url: `${this.$baseurl}/register-process/v1/business`,
      headers: this.headers,
      ...config,
    });
  },
  async whitelistedCompanies(search = '', config = {}) {
    return this.$get({
      url: `${this.$baseurl}/register-process/v2/companies?search=${search}`,
      headers: this.headers,
      ...config,
    });
  },
};
