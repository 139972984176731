export default {
  async all(userId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/staff/card/repayment/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/card/repayment/setup`,
      headers: this.headers,
      ...config,
    });
  },
};
