export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/cards`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/creditCard/create`,
      headers: this.headers,
      ...config,
    });
  },
  async fund(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/wallet/assign-credit`,
      headers: this.headers,
      ...config,
    });
  },
  async increase_limit(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/account/set-account-limit`,
      headers: this.headers,
      ...config,
    });
  },
};
