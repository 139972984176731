export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/loans`,
      headers: this.headers,
      ...config,
    });
  },
  async request(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/loans/request`,
      headers: this.headers,
      ...config,
    });
  },
  async view(loanId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/loans/${loanId}`,
      headers: this.headers,
      ...config,
    });
  },
  offerLetter: require('./offerLetter').default,
};
