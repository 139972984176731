export default {
  async roles(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/permissions/roles`,
      headers: this.headers,
      ...config,
    });
  },
  async users(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/permissions/users/${this.user.company_id}`,
      headers: this.headers,
      ...config,
    });
  },
  async update(config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/companies/permissions/users/update`,
      headers: this.headers,
      ...config,
    });
  },
};
