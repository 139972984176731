export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/credit-applications`,
      headers: this.headers,
      ...config,
    });
  },
  async approve(applicationId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/credit-applications/approve/${applicationId}`,
      headers: this.headers,
      ...config,
    });
  },
  async decline(applicationId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/credit-applications/decline/${applicationId}`,
      headers: this.headers,
      ...config,
    });
  },
};
