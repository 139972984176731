import { accountTypes } from './utils/pages_utils';

export default {

  subscription: {
    route: {
      name: 'subscription',
      path: `/:accountType(${accountTypes})/subscription`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'subscription' */ '@/views/app/subscription/subscribe/Index.vue'
        ),
      meta: {
        title: 'Subscribe',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
    ],
    permissions: [
      'company:can_view_subscription_plans',
      'company:can_subscribe_to_plan',
      'personal:can_view_subscription_plans',
      'personal:can_subscribe_to_plan',
    ],
  },

  'subscription-upgrade': {
    route: {
      name: 'subscription-upgrade',
      path: `/:accountType(${accountTypes})/subscription/upgrade`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'subscription-upgrade' */ '@/views/app/subscription/subscribe/Index.vue'
        ),
      meta: {
        title: 'Subscribe',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'company:can_view_subscription_plans',
      'company:can_subscribe_to_plan',
      'personal:can_view_subscription_plans',
      'personal:can_subscribe_to_plan',
    ],
  },

}