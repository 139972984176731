export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admins`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admins`,
      headers: this.headers,
      ...config,
    });
  },
  async update(adminId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admins/${adminId}`,
      headers: this.headers,
      ...config,
    });
  },
  async delete(adminId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admins/delete/${adminId}`,
      headers: this.headers,
      ...config,
    });
  },
};
