export default {
  async refute(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/bank-account/refute`,
      headers: this.headers,
      ...config,
    });
  },
  async verify(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/users/${userId}/bank-account/verify`,
      headers: this.headers,
      ...config,
    });
  },
};
