export default {
  async loanOfferLetter(loanId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/loans/${loanId}/offer-letter`,
      headers: this.headers,
      ...config,
    });
  },
  async accept(offerLetterId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/loans/offer-letter/${offerLetterId}/accept`,
      headers: this.headers,
      ...config,
    });
  },
  async reject(offerLetterId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/loans/offer-letter/${offerLetterId}/reject`,
      headers: this.headers,
      ...config,
    });
  },
};
