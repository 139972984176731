export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/affiliates/rewards`,
      headers: this.headers,
      ...config,
    });
  },
  async disbursed(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/affiliates/rewards/all-batch-request-to-withdraw?status=disbursed`,
      headers: this.headers,
      ...config,
    });
  },
  async pending(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/affiliates/rewards/all-batch-request-to-withdraw?status=processing`,
      headers: this.headers,
      ...config,
    });
  },
  async total(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/affiliate/rewards/total`,
      headers: this.headers,
      ...config,
    });
  },
};
