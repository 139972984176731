export default {
  async createInvestmentAction(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/invest/admin/actions`,
      headers: this.headers,
      ...config,
    });
  },
  async reviewInvestmentAction(actionId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/invest/admin/actions/${actionId}`,
      headers: this.headers,
      ...config,
    });
  },
  async activatePndOnInvestment(investmentId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/invest/admin/${investmentId}/activate-pnd`,
      headers: this.headers,
      ...config,
    });
  },
  async deactivatePndOnInvestment(investmentId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/invest/admin/${investmentId}/deactivate-pnd`,
      headers: this.headers,
      ...config,
    });
  },
};
