import { path, admin, accountTypes } from './utils/pages_utils';

export default {
  'dollar-savings': {
    route: {
      name: 'dollar-savings',
      path: path('dollar-savings', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'dollar-savings' */ '@/views/app/savings/dollar/list/Index.vue'
        ),
      meta: {
        title: 'Dollar Savings',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_user_savings'],
  },
  'flexi-savings': {
    route: {
      name: 'flexi-savings',
      path: path('flexi-savings', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'flexi-savings' */ '@/views/app/savings/flexi/list/Index.vue'
        ),
      meta: {
        title: 'Flexi Savings',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_user_savings'],
  },
  'vault-savings': {
    route: {
      name: 'vault-savings',
      path: path('vault-savings', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'vault-savings' */ '@/views/app/savings/vault/list/Index.vue'
        ),
      meta: {
        title: 'Vault Savings',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_user_savings'],
  },
  'dollar-saving-history': {
    route: {
      name: 'dollar-saving-history',
      path: `/:accountType(${accountTypes})/savings/:savingId/user/:userId/history`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'dollar-saving-history' */
          '@/views/app/savings/dollar/saving-history/view/Index.vue'
        ),
      meta: {
        title: 'Dollar Saving History',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_user_savings'],
  },
  'flexi-saving-history': {
    route: {
      name: 'flexi-saving-history',
      path: `/:accountType(${accountTypes})/savings/:savingId/user/:userId/history`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'flexi-saving-history' */
          '@/views/app/savings/flexi/saving-history/view/Index.vue'
        ),
      meta: {
        title: 'Flexi Saving History',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_user_savings'],
  },
  'vault-saving-history': {
    route: {
      name: 'vault-saving-history',
      path: `/:accountType(${accountTypes})/savings/:savingId/user/:userId/history`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'vault-saving-history' */
          '@/views/app/savings/vault/saving-history/view/Index.vue'
        ),
      meta: {
        title: 'Vault Saving History',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_user_savings'],
  },
  'savings-configuration': {
    route: {
      name: 'savings-configuration',
      path: path('savings-configuration', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'savings-configuration' */ '@/views/app/savings/Configuration.vue'
        ),
      meta: {
        title: 'Savings Configuration',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_savings_configuration'],
  },

    'invest-savings-configuration': {
        route: {
          name: 'invest-savings-configuration',
          path: path('invest-savings-configuration', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'invest-savings-configuration' */ '@/views/app/invest/SavingsConfiguration.vue'
            ),
          meta: {
            title: 'Invest Savings Configuration',
          },
        },
        middlewares: ['auth', 'password_changed'],
        permissions: ['credpal:can_view_savings_configuration'],
      },
}