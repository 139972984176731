import { path, admin, corporate, customer } from './utils/pages_utils';

export default {

  'settings-profile': {
    route: {
      name: 'settings-profile',
      path: path(`settings/profile`, customer),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'settings-profile' */ '@/views/app/settings/profile/Index.vue'
        ),
      meta: {
        title: 'Update Profile',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: [
      'personal:can_view_personal_profile',
      'personal:can_update_personal_profile',
      'personal:can_upload_personal_documents',
    ],
  },
  'settings-profile-company': {
    route: {
      name: 'settings-profile-company',
      path: path('settings/profile/company', corporate),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'settings-profile-company' */ '@/views/app/settings/CompanyProfile.vue'
        ),
      meta: {
        title: 'Company Profile',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: [
      'company:can_view_company_profile',
      'company:can_update_company_profile',
      'company:can_upload_company_documents',
    ],
  },
  'settings-password': {
    route: {
      name: 'settings-password',
      path: path(`settings/password`, '*'),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'settings-password' */ '@/views/app/settings/Password.vue'
        ),
      meta: {
        title: 'Change Your Password',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: [
      'credpal:can_update_password',
      'company:can_update_password',
      'personal:can_update_password',
    ],
  },
  'settings-configuration': {
    route: {
      name: 'settings-configuration',
      path: path('settings/configuration', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'settings-configuration' */ '@/views/app/settings/configuration/Index.vue'
        ),
      meta: {
        title: 'Configurations',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_update_system_configuration'],
  },
  'auth-bypass': {
    route: {
      name: 'auth-bypass',
      path: path('settings/bypass', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'auth-bypass' */ '@/views/app/settings/Bypass.vue'
        ),
      meta: {
        title: 'Authentication Bypass',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: [
      'credpal:can_update_system_configuration',
      'credpal:can_edit_bypass',
    ],
  },
  'maintenance-mail': {
    route: {
      name: 'maintenance-mail',
      path: path('settings/mails', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'notifications' */ '@/views/app/settings/MaintenanceMail.vue'
        ),
      meta: {
        title: 'Maintenance Mails',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_update_system_configuration'],
  },
  notifications: {
    route: {
      name: 'notifications',
      path: path('notifications', '*'),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'notifications' */ '@/views/app/settings/Notifications.vue'
        ),
      meta: {
        title: 'Notifications',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },

  /** Configurations */

  'cash-configuration': {
    route: {
      name: 'cash-configuration',
      path: path('cash/configuration', admin),
      parent: 'app',
      component: () => import('@/views/app/cash/configuration/Index.vue'),
      meta: {
        title: 'Cash Configuration',
      },
    },

    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_cash_configuration'],
  },
}