export default {
  async configurations(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/configurations/staff/${this.user?.id}`,
      headers: this.headers,
      ...config,
    });
  },
  bills: require('./bills').default,
  cards: require('./cards').default,
  documents: require('./documents').default,
  expenseCategories: require('./expenseCategories').default,
  expenseRequests: require('./expenseRequests').default,
  expenses: require('./expenses').default,
  loans: require('./loans').default,
  marketplace: require('./marketplace').default,
  onboarding: require('./onboarding').default,
  profile: require('./profile').default,
  repaymentCards: require('./repaymentCards').default,
  repayments: require('./repayments').default,
  shareAndEarn: require('./shareAndEarn').default,
  subscription: require('./subscription').default,
  invest: require('./invest').default,
  transfers: require('./transfers').default,
  wallet: require('./wallet').default,
};
