import { path, admin } from './utils/pages_utils';

export default {
    'bank-accounts': {
        route: {
          name: 'bank-accounts',
          path: path('bank-accounts', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'bank-accounts' */ '@/views/app/bank-accounts/Index.vue'
            ),
          meta: {
            title: 'Bank Account Verification & Bypass',
          },
        },
        middlewares: ['auth', 'password_changed'],
        permissions: ['credpal:can_view_bank_accounts'],
      },
      'bank-accounts-pending': {
        route: {
          name: 'bank-accounts-pending',
          path: path('bank-accounts-pending', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'bank-accounts-pending' */ '@/views/app/bank-accounts/Pending.vue'
            ),
          meta: {
            title: 'Bank Account Verification & Bypass',
          },
        },
        middlewares: ['auth', 'password_changed'],
        permissions: ['credpal:can_verify_bank_accounts'],
      },
}