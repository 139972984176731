import { accountTypes } from './utils/pages_utils';

export default {
    staff: {
        route: {
          name: 'staff',
          path: `/:accountType(${accountTypes})/staff`,
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'staff-index' */ '@/views/app/staff/all/Index.vue'
            ),
          meta: {
            title: 'Employees',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'credpal:can_view_staff',
          'credpal:can_update_staff',
          'credpal:can_delete_staff',
          'company:can_view_staff',
          'company:can_update_staff',
          'company:can_delete_staff',
        ],
      },
  'staff-new': {
    route: {
      name: 'staff-new',
      path: `/:accountType(${accountTypes})/staff/new`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'staff-new' */ '@/views/app/staff/new/Index.vue'
        ),
      meta: {
        title: 'Register An Employee',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_create_staff', 'company:can_create_staff'],
  },
  'employee-loan-history': {
    route: {
      name: 'employee-loan-history',
      path: `/:accountType(${accountTypes})/employee-loan-history`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'employee-loan-history' */ '@/views/app/loan_history/Employee.vue'
        ),
      meta: {
        title: 'Employee Loan History',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['company:can_view_loans'],
  },
  'staff-view': {
    route: {
      name: 'staff-view',
      path: `/:accountType(${accountTypes})/users/:staffId`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'staff-view' */ '@/views/app/staff/view/Index.vue'
        ),
      meta: {
        title: 'View Employees',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_staff_profile',
      'company:can_view_staff_profile',
    ],
  },

}