export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/affiliates`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/affiliates`,
      headers: this.headers,
      ...config,
    });
  },
  async conversions(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/affiliates/conversions`,
      headers: this.headers,
      ...config,
    });
  },
  async updateLead(leadId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/affiliates/leads/${leadId}`,
      headers: this.headers,
      ...config,
    });
  },
  rewards: require('./rewards').default,
  statistics: require('./statistics').default,
};
