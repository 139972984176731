export default {
  async approve(userId, config = {}) {
    console.log(userId);
    await this.$post({
      url: `${this.$baseurl}/admin/personal/users/appeal-requests/approve/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async decline(userId, config = {}) {
    await this.$post({
      url: `${this.$baseurl}/admin/personal/users/appeal-requests/decline/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
};
