export default {
  async employed(userId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/personal/users/${userId}/profile/employment/employed`,
      headers: this.headers,
      ...config,
    });
  },
  async selfEmployed(userId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/personal/users/${userId}/profile/employment/self-employed`,
      headers: this.headers,
      ...config,
    });
  },
};
