import { path, admin, corporate, customer, accountTypes } from './utils/pages_utils';

export default {
/**
 * ============================================================================================
 * Only Page groups with one page are added here
 * If you wish to add additional pages within a group, please seperate into it's own file
 * ============================================================================================
 */
  download: {
    route: {
      name: 'download',
      path: '/download',
      parent: 'root',
      component: () => import('@/views/misc/Download.vue'),
      meta: {
        title: 'Download the CredPal app',
        allowEndUsers: true,
      },
    },
    middlewares: [],
    permissions: ['*'],
  },

  'awaiting-approval': {
    route: {
      name: 'awaiting-approval',
      path: path('approval', customer, corporate),
      parent: 'app',
      component: () => import('@/views/app/awaiting-approval/Index.vue'),
      meta: {
        title: 'Awaiting Approval',
      },
    },
    middlewares: ['auth'],
    permissions: ['*'],
  },

  dashboard: {
    route: {
      name: 'dashboard',
      path: path('dashboard', '*'),
      parent: 'app',
      component: () => import('@/views/app/dashboard/Index.vue'),
      meta: {
        title: 'Dashboard',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },

  'offer-letter': {
    route: {
      name: 'offer-letter',
      path: path('offer-letter/:loanId', admin, customer),
      parent: 'root',
      component: () =>
        import(
          /* webpackChunkName: 'offer-letter' */ '@/views/app/offer-letter/Index.vue'
        ),
      meta: {
        title: 'Offer Letter',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_offer_letter',
      'personal:can_view_offer_letter',
    ],
  },

  wallet: {
    route: {
      name: 'wallet',
      path: path(`wallet`, corporate),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'wallet-index' */ '@/views/app/wallet/Index.vue'
        ),
      meta: {
        title: 'Wallet',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_wallet',
      'credpal:can_fund_debit_wallet',
      'credpal:can_apply_for_credit',
      'company:can_view_wallet',
      'company:can_fund_debit_wallet',
      'company:can_apply_for_credit',
    ],
  },

  analytics: {
    route: {
      name: 'analytics',
      path: path(`analytics`, corporate),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'analytics-index' */ '@/views/app/analytics/Index.vue'
        ),
      meta: {
        title: 'Account Summary',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['company:can_view_analytics'],
  },

  communications: {
    route: {
      name: 'communications',
      path: `/:accountType(${accountTypes})/communications`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'communications' */ '@/views/app/communications/Index.vue'
        ),
      meta: {
        title: 'Refunds',
      },
    },
    middlewares: ['auth'],
    permissions: ['credpal:can_create_communications'],
  },
  'communication-templates': {
    route: {
      name: 'communication-templates',
      path: `/:accountType(${accountTypes})/communication-templates`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'communication-templates-index' */ '@/views/app/communication-templates/Index.vue'
        ),
      meta: {
        title: 'All Communication Templates',
      },
    },
    middlewares: ['auth'],
    permissions: [
      'credpal:can_view_communication_templates',
      'credpal:can_create_communication_templates',
    ],
  },

  statement: {
    route: {
      name: 'statement',
      path: `/:accountType(${accountTypes})/statement`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'statements-index' */ '@/views/app/statements/all/Index.vue'
        ),
      meta: {
        title: 'Statement',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_statements',
      'credpal:can_delete_statements',
      'company:can_view_statements',
      'company:can_pay_statements',
      'personal:can_view_statements',
      'personal:can_pay_statements',
    ],
  },

  permissions: {
    route: {
      name: 'permissions',
      path: path('permissions', admin, corporate),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'permissions' */ '@/views/app/permissions/Index.vue'
        ),
      meta: {
        title: 'Permissions',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_permissions',
      'credpal:can_modify_permissions',
      'company:can_view_permissions',
      'company:can_modify_permissions',
    ],
  },

  marketplace: {
    route: {
      name: 'marketplace',
      path: path('marketplace', customer),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'marketplace' */ '@/views/app/marketplace/Index.vue'
        ),
      meta: {
        title: 'Marketplace',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'order-history': {
    route: {
      name: 'order-history',
      path: path('order-history', customer),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'order-history' */ '@/views/app/marketplace/OrderHistory.vue'
        ),
      meta: {
        title: 'Order History',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },

  'identity-verifications': {
    route: {
      name: 'identity-verifications',
      path: path('identity-verifications', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'identity-verifications' */ '@/views/app/identity-verifications/Index.vue'
        ),
      meta: {
        title: 'Identity Verifications',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_identity_verifications'],
  },
}