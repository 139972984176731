export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/card/requests/all`,
      headers: this.headers,
      ...config,
    });
  },
  async approve(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/wallet/set-wallet-limit`,
      headers: this.headers,
      ...config,
    });
  },
};
