export default {
  async merchants(limit, search, filters = [], config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/merchant?limit=${limit}&search=${
        search ?? ''
      }&filter=${filters ?? ''}`,
      headers: this.headers,
      ...config,
    });
  },
  async branch(vendorId, search, config = {}) {
    // const url =
    //   typeof search === undefined || search === null
    //     ? `${this.$baseurl}/merchant/${vendorId}/branch`
    //     : `${this.$baseurl}/merchant/${vendorId}/branch?search=${search}`;
    return await this.$get({
      url: `${this.$baseurl}/merchant/${vendorId}/branch?search=${
        search ?? ''
      }`,
      headers: this.headers,
      ...config,
    });
  },
  async categories(limit, search, filters = [], config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/merchant/industry?limit=${limit}&search=${
        search ?? ''
      }&filter=${filters ?? ''}`,
      headers: this.headers,
      ...config,
    });
  },
  async categoryVendors(categoryId, search, config = {}) {
    const url =
      typeof search === undefined || search === ''
        ? `${this.$baseurl}/merchant/${categoryId}/merchants`
        : `${this.$baseurl}/merchant/${categoryId}/merchants?search=${search}`;

    return await this.$get({
      url: url,
      headers: this.headers,
      ...config,
    });
  },
  async deals(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/merchant/deal`,
      headers: this.headers,
      ...config,
    });
  },
  async placeOrder(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/merchant/create-order`,
      headers: this.headers,
      ...config,
    });
  },
  async confirmOrder(orderId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/merchant/${orderId}/confirm-order`,
      headers: this.headers,
      ...config,
    });
  },
  async cancelOrder(orderId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/merchant/${orderId}/cancel-order`,
      headers: this.headers,
      ...config,
    });
  },
  async spreadPayment(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/merchant/spread-payment`,
      headers: this.headers,
      ...config,
    });
  },
  async orderHistory(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/users/transaction/transfer-purchases`,
      headers: this.headers,
      ...config,
    });
  },
  paymentSummary: require('./paymentSummary').default,
};
