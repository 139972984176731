export default {
  async cashbacks(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/transactions/cashback`,
      headers: this.headers,
      ...config,
    });
  },
  async rewards(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/wallet/reward`,
      headers: this.headers,
      ...config,
    });
  },
  async users(userId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/wallet/${userId}/reward`,
      headers: this.headers,
      ...config,
    });
  },
  async userCashback(userId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/${userId}/cashback`,
      headers: this.headers,
      ...config,
    });
  },
  async transactions(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/users/wallet/transactions`,
      headers: this.headers,
      ...config,
    });
  },
};
