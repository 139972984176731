export default {
  async list(params, config = {}) {
    const query = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    return await this.$get({
      url: `${this.$baseurl}/admin/personal/communication-templates?${query}`,
      headers: this.headers,
      params,
      ...config,
    });
  },
  async create(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/communication-templates`,
      headers: this.headers,
      ...config,
    });
  },
  async update(templateId, config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/admin/personal/communication-templates/${templateId}`,
      headers: this.headers,
      ...config,
    });
  },
  async delete(templateId, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/admin/personal/communication-templates/${templateId}`,
      headers: this.headers,
      ...config,
    });
  },
};
