export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/corporate/cards`,
      headers: this.headers,
      ...config,
    });
  },
  async repayments(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/corporate/cards/repayments`,
      headers: this.headers,
      ...config,
    });
  },
};
