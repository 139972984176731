export default {
  async debit(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/cash/debit`,
      headers: this.headers,
      ...config,
    });
  },
  async addLimit(userId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/cash/add-limit/${userId}`,
      headers: this.headers,
      ...config,
    });
  },
  async approveAwaitingCashTransfer(cashTransferId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/cash/awaiting-approval/${cashTransferId}/approve`,
      headers: this.headers,
      ...config,
    });
  },
  async rejectAwaitingCashTransfer(cashTransferId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/cash/awaiting-approval/${cashTransferId}/reject`,
      headers: this.headers,
      ...config,
    });
  },
  async markSuccessAwaitingCashTransfer(cashTransferId, config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/cash/awaiting-approval/${cashTransferId}/mark-success`,
      headers: this.headers,
      ...config,
    });
  },
};
