export default {
  async update(config = {}) {
    return await this.$put({
      url: `${this.$baseurl}/personal/profile`,
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      ...config,
    });
  },
};
