import { path, admin, customer } from './utils/pages_utils'

export default {
  merchants: {
    route: {
      name: 'merchants',
      path: path('merchants', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'merchants' */ '@/views/app/merchant/Index.vue'
        ),
      meta: {
        title: 'Merchants',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'new-merchant': {
    route: {
      name: 'new-merchant',
      path: path('merchants/create', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'merchants-create' */ '@/views/app/merchant/Create.vue'
        ),
      meta: {
        title: 'Create Merchant',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-category': {
    route: {
      name: 'merchant-category',
      path: path('merchants/category', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'merchants-category' */ '@/views/app/merchant/Category.vue'
        ),
      meta: {
        title: 'Category',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-profile': {
    route: {
      name: 'merchant-profile',
      path: path('merchants/profile/:id', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'merchants-profile' */ '@/views/app/merchant/profile/Index.vue'
        ),
      meta: {
        title: 'Merchant Profile',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-anonymous-orders': {
    route: {
      name: 'merchant-anonymous-orders',
      path: path('merchants/anonymous-orders', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'merchant-anonymous-orders' */ '@/views/app/merchant/orders/AnonymousOrders.vue'
        ),
      meta: {
        title: 'Merchant Anonymous Orders',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-pending': {
    route: {
      name: 'merchant-pending',
      path: path('merchants/awaiting-approval', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'merchants-pending' */ '@/views/app/merchant/AwaitingApproval.vue'
        ),
      meta: {
        title: 'Merchant Awaiting Approval',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-settlements': {
    route: {
      name: 'merchant-settlements',
      path: path('merchants/settlements', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'merchant-settlements' */ '@/views/app/merchant/settlements/Index.vue'
        ),
      meta: {
        title: 'Merchant Settlements',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'equity-contribution': {
    route: {
      name: 'equity-contribution',
      path: path('equity-contribution', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'equity-contribution' */ '@/views/app/merchant/EquityContributions.vue'
        ),
      meta: {
        title: 'Equity Contribution',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'device-financing-orders': {
    route: {
      name: 'device-financing-orders',
      path: path('device-financing-orders', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'device-financing' */ '@/views/app/merchant/device-financing/Index.vue'
        ),
      meta: {
        title: 'Device Financing Orders',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-payouts': {
    route: {
      name: 'merchant-payouts',
      path: path('merchant-payouts', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'equity-contribution' */ '@/views/app/merchant/payouts/Index.vue'
        ),
      meta: {
        title: 'Merchant Payout',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-caas-merchants': {
    route: {
      name: 'merchant-caas-merchants',
      path: path('merchant-caas-merchants', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'equity-contribution' */ '@/views/app/merchant/caas/Merchants.vue'
        ),
      meta: {
        title: 'Merchant List',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-leads': {
    route: {
      name: 'merchant-leads',
      path: path('merchant-leads', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'equity-contribution' */ '@/views/app/merchant/caas/Leads.vue'
        ),
      meta: {
        title: 'Merchant Leads',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-transactions': {
    route: {
      name: 'merchant-transactions',
      path: path('merchant-transactions', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'equity-contribution' */ '@/views/app/merchant/caas/merchantTransactions/Index.vue'
        ),
      meta: {
        title: 'Merchant Transactions',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  'merchant-spend': {
    route: {
      name: 'merchant-spend',
      path: path('merchant-spend', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'equity-contribution' */ '@/views/app/merchant/caas/Spend.vue'
        ),
      meta: {
        title: 'Merchant Transactions',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  cashbacks: {
    route: {
      name: 'cashbacks',
      path: path('cashbacks', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'cashbacks' */ '@/views/app/rewards/cashbacks/Index.vue'
        ),
      meta: {
        title: 'Cashback',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
  wallets: {
    route: {
      name: 'wallets',
      path: path('wallets', admin, customer),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'wallets' */ '@/views/app/rewards/Wallets.vue'
        ),
      meta: {
        title: 'Reward Wallet',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'wallets-redemption': {
    route: {
      name: 'wallets-redemption',
      path: path('wallets/redemption', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'wallet-redemptions' */ '@/views/app/rewards/WalletsRedemption.vue'
        ),
      meta: {
        title: 'Reward Wallet Redemption',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants'],
  },
}