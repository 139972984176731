import { accountTypes } from './utils/pages_utils';

export default {
  companies: {
    route: {
      name: 'companies',
      path: `/:accountType(${accountTypes})/companies`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'companies-index' */ '@/views/app/companies/Index.vue'
        ),
      meta: {
        title: 'Companies',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_view_companies', 'credpal:can_update_companies'],
  },
  'companies-requests': {
    route: {
      name: 'companies-requests',
      path: `/:accountType(${accountTypes})/companies/requests`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'companies-requests' */ '@/views/app/companies/Requests.vue'
        ),
      meta: {
        title: 'Company Applications',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_company_requests',
      'credpal:can_approve_company_requests',
      'credpal:can_decline_company_requests',
    ],
  },
  'companies-users': {
    route: {
      name: 'companies-users',
      path: `/:accountType(${accountTypes})/companies/users/:company_id`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'companies-users' */ '@/views/app/companies/Users.vue'
        ),
      meta: {
        title: 'Company Users',
      },
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_staff'],
  },
  'companies-new': {
    route: {
      name: 'companies-new',
      path: `/:accountType(${accountTypes})/companies/new`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'companies-new' */ '@/views/app/companies/New.vue'
        ),
      meta: {
        title: 'Register A New Company',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_create_companies'],
  },
  'companies-statistics': {
    route: {
      name: 'companies-statistics',
      path: `/:accountType(${accountTypes})/companies/statistics`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'companies-statistics' */ '@/views/app/companies/Statistics.vue'
        ),
      meta: {
        title: 'Register A New Company',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['credpal:can_create_companies'],
  },
}