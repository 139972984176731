export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/cards/all/${this.user.company_id}`,
      headers: this.headers,
      ...config,
    });
  },
  async transactions(accountId, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/account/transactions/${accountId}`,
      headers: this.headers,
      ...config,
    });
  },
  credit: require('./credit').default,
  debit: require('./debit').default,
};
