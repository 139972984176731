<template>
  <div
    id="app"
    :class="{
      'sidebar-open': sidebar.open,
      [`${this.$route.name}-page`]: true
    }"
  >
    <transition>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </transition>

    <nprogress-container />
    <PreLoader />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
import events from './config/events';

export default {
  components: {
    NprogressContainer,
    PreLoader: require('@/components/loaders/PreLoader.vue').default
  },
  data() {
    return {
      sessionInterval: null
    };
  },
  computed: {
    ...mapState('design', ['sidebar']),
    ...mapState('session', ['expires_at', 'loggedIn'])
  },
  beforeCreate() {
    this.$store.dispatch('session/loadUser');
  },
  async mounted() {
    // this.checkSessionExpiry();
    await this.loadSession();
    await this.unreadNotifications();
    this.ready();
    this.setInterval();
    this.$root.$on('login', () => {
      this.setInterval();

      this.reloadIntercom(this.$store.state.session.user);

      // this.$store.dispatch('resources/fetchAll');
    });

    this.$root.$on('global', data => {
      events[data.event]?.apply(this, data.params);
    });
  },
  methods: {
    ...mapActions('session', ['logout', 'updateUser']),
    ...mapMutations('design', ['closeSidebar']),
    ...mapMutations('session', ['ready']),

    checkSessionExpiry() {
      const time = this.expires_at;
      if (time) {
        const now = new Date().getTime();

        if (now >= time) {
          clearInterval(this.sessionInterval);

          this.logout();
          if (!this.$route.name.match(/login|register|upload-documents/)) {
            this.$router.push({
              name: 'logout',
              query: {
                expired: true,
                redirect: this.$route.fullPath
              }
            });
          }
        }
      }
    },
    reloadIntercom(user) {
      window.intercomSettings = {
        ...window.intercomSettings,
        name: user.name,
        email: user.email,
        created_at: user.created_at
      };

      if (window.Intercom) {
        window.Intercom('update', window.intercomSettings);
      }
    },
    async loadSession() {
      if (this.loggedIn) {
        await this.$get({
          url: `${this.$baseurl}/session`,
          headers: this.headers,
          success: async response => {
            this.updateUser(response.data.user);
            this.$root.$emit('global', {
              event: 'sessionUpdate',
              params: [response.data.user]
            });
            if (response.data.keys) {
              this.$store.commit('session/updateKeys', response.data.keys);
              this.$store.commit(
                'session/updatePermissions',
                response.data.user?.permissions?.map(
                  permission => permission.slug
                )
              );
              // this.$store.commit('session/updatePersonalStatement', response.data.personal_statement);
              this.$store.commit(
                'session/setConfiguration',
                response.data.configuration
              );
              this.$store.commit(
                'session/updateRoles',
                response.data.user?.roles
              );
              this.$store.commit(
                'session/updateCorporateCardCount',
                response.data.corporate_card_count
              );
            }

            this.reloadIntercom(response.data.user);

            const modals = response.data.closed_modals;
            if (modals && modals.length) {
              modals.forEach(modal => {
                this.$store.commit('modals/closeModal', { modal: modal.key });
              });
            }

            // this.$store.dispatch('resources/fetchAll', this);
          },
          error: error => {
            this.logout();
            console.log('Logged out');
            console.log(error);
          }
        });
      }
    },
    setInterval() {
      this.sessionInterval = setInterval(this.checkSessionExpiry, 1000);
    }
  }
};
</script>
