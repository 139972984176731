import { path, admin } from './utils/pages_utils';

export default {

  'upload-documents': {
    route: {
      name: 'upload-documents',
      path: '/upload-documents/token/:token',
      parent: 'root',
      component: () => import(/**/ '@/views/misc/upload-documents/Index.vue'),
      meta: {
        title: 'Upload Documents',
      },
    },
    middlewares: [],
    permissions: ['*'],
  },
  'rejected-docs': {
    route: {
      name: 'rejected-docs',
      path: path('doc-processor/rejected-docs', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'rejected-docs' */ '@/views/app/doc-processor/RejectedDocs.vue'
        ),
      meta: {
        title: 'Users Rejected Docs',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'new-docs': {
    route: {
      name: 'new-docs',
      path: path('doc-processor/new-docs', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'new-docs' */ '@/views/app/doc-processor/NewDocs.vue'
        ),
      meta: {
        title: 'Users New Uploaded Docs',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'doc-processor': {
    route: {
      name: 'doc-processor',
      path: path('doc-processor', admin),
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'doc-processor' */ '@/views/app/doc-processor/Index.vue'
        ),
      meta: {
        title: 'Users Statement Doc Processor',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
}