export default {
  activities: require('./activities').default,
  admins: require('./admins').default,
  beneficiaries: require('./beneficiaries').default,
  cards: require('./cards').default,
  creditApplication: require('./creditApplication').default,
  expenseCategories: require('./expenseCategories').default,
  expenseGroups: require('./expenseGroups').default,
  expensePolicies: require('./expensePolicies').default,
  expenseSubCategories: require('./expenseSubCategories').default,
  groups: require('./groups').default,
  permissions: require('./permissions').default,
  repaymentCards: require('./repaymentCards').default,
  staff: require('./staff').default,
  statements: require('./statements').default,
  subscription: require('./subscription').default,
  transfers: require('./transfers').default,
  wallet: require('./wallet').default,
};
