export default {
  async transfer(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/transfer/initiate`,
      headers: this.headers,
      ...config,
    });
  },
  async transactions(accountId, month = 0, config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/personal/card/transactions/${accountId}?month=${month}`,
      headers: this.headers,
      ...config,
    });
  },
  corporate: require('./corporate').default,
  personal: require('./personal').default,
};
