export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/cards`,
      headers: this.headers,
      ...config,
    });
  },
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/debitCard/create`,
      headers: this.headers,
      ...config,
    });
  },
  async fund(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/wallet/assign-debit`,
      headers: this.headers,
      ...config,
    });
  },
};
