export default {
  async add(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/merchants/region`,
      headers: this.headers,
      ...config,
    });
  },
  async destroy(id, config = {}) {
    return await this.$_delete({
      url: `${this.$baseurl}/admin/merchants/region/${id}`,
      headers: this.headers,
      ...config,
    });
  },
};
