export default {
  async requestOtp(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/work-email/request-otp`,
      headers: this.headers,
      ...config,
    });
  },
  async verifyOtp(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/work-email/verify-otp`,
      headers: this.headers,
      ...config,
    });
  },
};
