export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies`,
      headers: this.headers,
      ...config,
    });
  },
  async requests(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/requests`,
      headers: this.headers,
      ...config,
    });
  },
  async statistics(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/corporate/companies/statistics`,
      headers: this.headers,
      ...config,
    });
  },
  creditApplications: require('./creditApplications').default,
  loans: require('./loans').default,
};
