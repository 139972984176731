import Vue from 'vue';

Vue.mixin({
  methods: {
    parseObjectToCsvFile(arr) {
      if (arr.length === 0) {
        console.error('Array is empty. Unable to generate CSV.');
        return '';
      }

      // This line relies on the assumption that the first object of the array will dictate the schema for the rest.
      // If you have inconsistencies in your array objects, do not use, or at least upgrade this method to handle this edge case
      const headers = Object.keys(arr[0]);

      const csvRows = [];
      csvRows.push(headers.join(','));

      for (const row of arr) {
        const values = headers.map((header) => {
          const cellValue = row[header];
          // Handle cases where the value may contain commas or quotes
          return typeof cellValue === 'string' && cellValue.includes(',')
            ? `"${cellValue}"`
            : cellValue;
        });
        csvRows.push(values.join(','));
      }

      let data = new Blob([csvRows.join('\n')], { type: 'text/plain' });
      let url = URL.createObjectURL(data);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      return true;
    },
  },
});
