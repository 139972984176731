export default {
  async sendToTangarine(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/cards/tangerine/disburse`,
      headers: this.headers,
      ...config,
    });
  },
  async payTangerine(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/cards/tangerine/pay-many`,
      headers: this.headers,
      ...config,
    });
  },
  async payTangerineBatch(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/admin/personal/cards/tangerine/pay-batch`,
      headers: this.headers,
      ...config,
    });
  },
};
