export default {
  async backendCheck(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/creditLimit/request`,
      headers: this.headers,
      ...config,
    });
  },
  async submitApplication(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/creditLimit/request/final`,
      headers: this.headers,
      ...config,
    });
  },
  async documents(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/documents/upload`,
      headers: this.headers,
      ...config,
    });
  },
};
