export default {
  async request(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/expense/request`,
      headers: this.headers,
      ...config,
    });
  },
  async report(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/account/expense/update`,
      headers: this.headers,
      ...config,
    });
  },
};
