export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/corporate/loans`,
      headers: this.headers,
      ...config,
    });
  },
  async approved(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/corporate/loans/approved`,
      headers: this.headers,
      ...config,
    });
  },
  async disbursed(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/admin/corporate/loans/disbursed`,
      headers: this.headers,
      ...config,
    });
  },
};
