export default {
  async new(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/personal/debit-card/transfer`,
      headers: this.headers,
      ...config,
    });
  },
  async clearBalanceTransfer(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/repayment/personal/instant-transfer`,
      headers: this.headers,
      ...config,
    });
  },
  async loanRepaymentTransfer(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/staff/repayments/transfer`,
      headers: this.headers,
      ...config,
    });
  },
};
