import { path, corporate } from './utils/pages_utils';


export default {
    beneficiaries: {
        route: {
          name: 'beneficiaries',
          path: path('beneficiaries', corporate),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'beneficiaries' */ '@/views/app/beneficiaries/all/Index.vue'
            ),
          meta: {
            title: 'Beneficiaries',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'company:can_view_beneficiaries',
          'company:can_create_beneficiaries',
          'company:can_update_beneficiaries',
          'company:can_delete_beneficiaries',
        ],
      },
      'beneficiaries-new': {
        route: {
          name: 'beneficiaries-new',
          path: path('beneficiaries/new', corporate),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'beneficiaries-new' */ '@/views/app/beneficiaries/new/Index.vue'
            ),
          meta: {
            title: 'Beneficiaries',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: [
          'company:can_view_beneficiaries',
          'company:can_create_beneficiaries',
          'company:can_update_beneficiaries',
          'company:can_delete_beneficiaries',
        ],
      },
}