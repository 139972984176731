import { accountTypes } from './utils/pages_utils';

export default {
  activities: {
    route: {
      name: 'activities',
      path: `/:accountType(${accountTypes})/activities`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'activities-index' */ '@/views/app/activities/all/Index.vue'
        ),
      meta: {
        title: 'Activities',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_activities',
      'company:can_view_activities',
      'personal:can_view_activities',
    ],
  },
  'activities-recent': {
    route: {
      name: 'activities-recent',
      path: `/:accountType(${accountTypes})/activities/recent`,
      parent: 'app',
      component: () =>
        import(
          /* webpackChunkName: 'activities-recent' */ '@/views/app/activities/recent/Index.vue'
        ),
      meta: {
        title: 'Recent Activities',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: [
      'credpal:can_view_activities',
      'company:can_view_activities',
      'personal:can_view_activities',
    ],
  },


}