import { path, admin } from './utils/pages_utils';

export default {
    users: {
        route: {
          name: 'users',
          path: path('users', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-index' */ '@/views/app/users/Index.vue'
            ),
          meta: {
            title: 'Users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    'users-paid': {
        route: {
          name: 'users-paid',
          path: path('users/paid', admin),
          parent: 'app',
          component: () =>
            import(/*webpackChunkName: 'users-paid'*/ '@/views/app/users/Paid.vue'),
          meta: {
            title: 'Users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-active': {
        route: {
          name: 'users-active',
          path: path('users/active', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-active'*/ '@/views/app/users/Active.vue'
            ),
          meta: {
            title: 'Users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-inactive': {
        route: {
          name: 'users-inactive',
          path: path('users/inactive', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-inactive'*/ '@/views/app/users/Inactive.vue'
            ),
          meta: {
            title: 'Users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-fresh': {
        route: {
          name: 'users-fresh',
          path: path('users/fresh', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-fresh'*/ '@/views/app/users/leads/Fresh.vue'
            ),
          meta: {
            title: 'New users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-awaiting': {
        route: {
          name: 'users-awaiting',
          path: path('users/awaiting', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-awaiting'*/ '@/views/app/users/leads/Awaiting.vue'
            ),
          meta: {
            title: 'New users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-processing': {
        route: {
          name: 'users-processing',
          path: path('users/processing', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-processing'*/ '@/views/app/users/Processing.vue'
            ),
          meta: {
            title: 'New users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    
      'users-cold': {
        route: {
          name: 'users-cold',
          path: path('users/cold', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-cold'*/ '@/views/app/users/leads/Cold.vue'
            ),
          meta: {
            title: 'New users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-dump': {
        route: {
          name: 'users-dump',
          path: path('users/dump', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-dump'*/ '@/views/app/users/leads/Dump.vue'
            ),
          meta: {
            title: 'New users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-leads-statistics': {
        route: {
          name: 'users-leads-statistics',
          path: path('users/leads/statistics', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-leads-statistics'*/ '@/views/app/users/leads/Statistics.vue'
            ),
          meta: {
            title: 'New users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-active-inactive-statistics': {
        route: {
          name: 'users-active-inactive-statistics',
          path: path('users/active/statistics', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-active-inactive-statistics'*/ '@/views/app/users/Statistics.vue'
            ),
          meta: {
            title: 'Active User Statistics',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-new': {
        route: {
          name: 'users-new',
          path: path('users/new', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-new'*/ '@/views/app/users/new/Index.vue'
            ),
          meta: {
            title: 'Create a user',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-assigned': {
        route: {
          name: 'users-assigned',
          path: path('users/assigned', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-assigned'*/ '@/views/app/users/assigned/Index.vue'
            ),
          meta: {
            title: 'Users assigned to me',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    
      'users-approved': {
        route: {
          name: 'users-approved',
          path: path('users/approved', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-approved' */ '@/views/app/users/Approved.vue'
            ),
          meta: {
            title: 'Approved users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    
      'users-process': {
        route: {
          name: 'users-process',
          path: path('users/process', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-approved' */ '@/views/app/users/Process.vue'
            ),
          meta: {
            title: 'Processing users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    
      'users-pre-approved': {
        route: {
          name: 'users-pre-approved',
          path: path('users/pre-approved', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-pre-approved' */ '@/views/app/users/pre-approved/Index.vue'
            ),
          meta: {
            title: 'Pre-approved users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    
      'users-merchant-pre-approved': {
        route: {
          name: 'users-merchant-pre-approved',
          path: path('users/merchant/pre-approved', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-merchant-pre-approved' */ '@/views/app/users/merchant/Index.vue'
            ),
          meta: {
            title: 'Merchant Pre-approved users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    
      'users-incomplete': {
        route: {
          name: 'users-incomplete',
          path: path('users/incomplete', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-incomplete' */ '@/views/app/users/incomplete/Index.vue'
            ),
          meta: {
            title: 'Incomplete applications',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    
      'users-declined': {
        route: {
          name: 'users-declined',
          path: path('users/declined', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-declined' */ '@/views/app/users/Declined.vue'
            ),
          meta: {
            title: 'Declined credit applications',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    
      'users-limit-increase-request': {
        route: {
          name: 'users-limit-increase-request',
          path: path('users/limit-increase-request', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-limit-increase-request' */ '@/views/app/users/limit-increase/Index.vue'
            ),
          meta: {
            title: 'Limit Increase Requests',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
    
      'users-uncertain': {
        route: {
          name: 'users-uncertain',
          path: path('users/uncertain', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-uncertain' */ '@/views/app/users/Uncertain.vue'
            ),
          meta: {
            title: 'View uncertain users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-onboarding': {
        route: {
          name: 'users-onboarding',
          path: path('users/onboarding', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-onboarding' */ '@/views/app/users/onboarding/Index.vue'
            ),
          meta: {
            title: "Complete user's onboarding",
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-highend': {
        route: {
          name: 'users-highend',
          path: path('users/highend', admin),
          parent: 'app',
          component: () =>
            import(
              /* webpackChunkName: 'users-highend' */ '@/views/app/users/Highend.vue'
            ),
          meta: {
            title: 'View highend users',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['credpal:can_view_lead_qualifications'],
      },
    
      'users-applications': {
        route: {
          name: 'users-applications',
          path: path('users/applications', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-applications'*/ '@/views/app/users/Applications.vue'
            ),
          meta: {
            title: 'Applications',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-post-no-debit': {
        route: {
          name: 'users-post-no-debit',
          path: path('users/post-no-debit', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-applications'*/ '@/views/app/users/PostNoDebit.vue'
            ),
          meta: {
            title: 'Post No Debit',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },
      'users-appeal-requests': {
        route: {
          name: 'users-appeal-requests',
          path: path('users/appeal-requests', admin),
          parent: 'app',
          component: () =>
            import(
              /*webpackChunkName: 'users-applications'*/ '@/views/app/users/appeal-requests/Index.vue'
            ),
          meta: {
            title: 'Appeal Requests',
          },
        },
        middlewares: [
          'auth',
          'password_changed',
          'profile_updated',
          'profile_approved',
          'company_profile_updated',
          'company_subscribed',
        ],
        permissions: ['*'],
      },


  'users-new-affiliate': {
    route: {
      name: 'users-new-affiliate',
      path: path('affiliate/register', admin),
      parent: 'app',
      component: () => import('@/views/app/affiliate/new/Index.vue'),
      meta: {
        title: 'New Affiliate User',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'all-affiliate-users': {
    route: {
      name: 'all-affiliate-users',
      path: path('affiliate/all', admin),
      parent: 'app',
      component: () => import('@/views/app/affiliate/all/AllAffiliateUser.vue'),
      meta: {
        title: 'Affiliate Users',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'referred-users': {
    route: {
      name: 'referred-users',
      path: path('referred-users', admin),
      parent: 'app',
      component: () =>
        import(/**/ '@/views/app/share-and-earn/super/Referral.vue'),
      meta: {
        title: 'Referred Users',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  'users-referral-wallet': {
    route: {
      name: 'users-referral-wallet',
      path: path('users-referral-wallet', admin),
      parent: 'app',
      component: () =>
        import(/**/ '@/views/app/share-and-earn/super/ReferralWallet.vue'),
      meta: {
        title: 'Referred Users',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },
  
  'bank-statements': {
    route: {
      name: 'bank-statements',
      path: path('users/bank-statements', admin),
      parent: 'app',
      component: () =>
        import(
          /*webpackChunkName: 'bank-statements'*/ '@/views/app/users/BankStatement.vue'
        ),
      meta: {
        title: 'Bank Statement',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },

  'verification-method': {
    route: {
      name: 'verification-method',
      path: path('users/verification-method', admin),
      parent: 'app',
      component: () =>
        import(
          /*webpackChunkName: 'users-verification-method'*/ '@/views/app/users/verification/Index.vue'
        ),
      meta: {
        title: 'User Verification Method',
      },
    },
    middlewares: [
      'auth',
      'password_changed',
      'profile_updated',
      'profile_approved',
      'company_profile_updated',
      'company_subscribed',
    ],
    permissions: ['*'],
  },

}