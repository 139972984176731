export default {
  async all(config = {}) {
    return await this.$get({
      url: `${this.$baseurl}/companies/expense/request/pending`,
      headers: this.headers,
      ...config,
    });
  },
  async approve(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/expense/request/approve`,
      headers: this.headers,
      ...config,
    });
  },
  async decline(config = {}) {
    return await this.$post({
      url: `${this.$baseurl}/companies/expense/request/approve`,
      headers: this.headers,
      ...config,
    });
  },
};
