export default {
  async identityVerification(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/identity-verification`,
      headers: this.headers,
      ...config,
    });
  },
  async instantApproval(config = {}) {
    await this.$post({
      url: `${this.$baseurl}/personal/onboarding/v2/instant-approval`,
      headers: this.headers,
      ...config,
    });
  },
  async profile(config = {}) {
    await this.$put({
      url: `${this.$baseurl}/personal/onboarding/v2/profile`,
      headers: this.headers,
      ...config,
    });
  },
  bvn: require('./bvn').default,
  documents: require('./documents').default,
  nin: require('./nin').default,
  workEmail: require('./workEmail').default,
};
